import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router'

const TournamentItem = ({ match, tournamentId, matchId }) => {
  const navigate = useNavigate()

  const changeDateFormat = (originalDate) => {
    const parsedDate = moment(originalDate, 'YYYY-MM-DD HH:mm:ss')
    const newDate = parsedDate.format('MMM DD')
    return newDate
  }

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: '#2B2B3D',
        borderRadius: '12px',
        color: 'white',
        overflow: 'hidden',
        alignItems: 'center',
        mb: 1.5,
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/match/${matchId}`)}
    >
      <Box
        sx={{
          bgcolor: '#222232',
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          py: 1,
          px: 3,
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: '#46CDF7',
            textTransform: 'uppercase',
            fontSize: { sm: '18px', xs: '12px' },
          }}
        >
          {match.matchType}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: { sm: '18px', xs: '12px' },
          }}
        >
          {changeDateFormat(match.matchConfig.date)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
          flex: 1,
          pl: 2,
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: { sm: '18px', xs: '12px' },
            textTransform: 'uppercase',
          }}
        >
          {match.matchJson.info[0].teams[0].teamName}
        </Typography>
        <Typography
          sx={{ fontSize: { sm: '18px', xs: '12px' }, color: '#80808B' }}
        >
          vs
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: '18px', xs: '12px' },
            textTransform: 'uppercase',
          }}
        >
          {match.matchJson.info[0].teams[1].teamName}
        </Typography>
      </Box>
    </Box>
  )
}

export default TournamentItem