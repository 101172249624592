import axios from 'axios';
import moment from 'moment';
import {
  setLeagueTable,
  setUngroupedteams,
  setGroupedteams,
  setTournamentMatches,
  toggleTournamentLoading,
  setTournamentError,
} from './tournament.slice';

export const fetchPointsTable = (tournamentId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournament/leagueTable/${tournamentId}?user=65574129354096865f1ebf70`
      );
      const leagueTable = response.data.data.ungroupedTeamsLeagueTable;
      dispatch(setLeagueTable(leagueTable));
      const Ungroupedteams = response.data.data.ungroupedTeams;
      dispatch(setUngroupedteams(Ungroupedteams));
    } catch (error) {
      dispatch(setTournamentError(error?.response?.data || error?.message || error));
    }
  };
};

export const fetchTournamentMatches = (tournamentId,date) => {
  return async (dispatch) => {
    try {
      dispatch(toggleTournamentLoading());

      const currentDate = moment().format('DD-MM-YYYY');

      let url = `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournament/matches?tournamentId=${tournamentId}&page=1&pageSize=12`
      
      if (!date) {
        url += `&startDate=${currentDate}`;
      } else {
        url += `&startDate=${date}`;
      }

      const response = await axios.get(url)
      
      dispatch(setTournamentMatches(response.data))
      dispatch(toggleTournamentLoading())
    } catch (error) {
      console.error('Error fetching all matches:', error)
      dispatch(
        setTournamentError(error?.response?.data || error?.message || error)
      )
    }
  }
}