import React from 'react'
import './css/Table.css'

const Table = ({ columns }) => {
  return (
    <table>
      <thead>
        <tr>
          <th
            colSpan={2}
            style={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold' }}
          >
            MATCH DETAILS
          </th>
        </tr>
      </thead>
      <tbody>
        {columns.map((column, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'left', color: 'grey' }}>
              {column.label}
            </td>
            <td style={{ textAlign: 'left', color: 'black' }}>
              {column.imgUrl && (
                <img
                  src={column.imgUrl}
                  alt={column.label}
                  style={{
                    maxWidth: '50px',
                    maxHeight: '50px',
                    marginRight: '8px',
                  }}
                />
              )}
              {column.value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
