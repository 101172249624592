import React from 'react';
import { Box, Grid } from '@mui/material';
import StatsCard from '../../components/Cards/StatsCard';
import ThunderIcon from "../../assets/Thunder.svg";
import GraphIcon from "../../assets/Graph.svg";
import AvatarIcon from "../../assets/Avatar-Image.svg";

const PlayerStats = ({ player }) => {
    const { Matches, Runs, Wickets, Wins } = player || {};

    const styles = {
        container: {
            mt: 1.5,
            maxWidth: 1200,
            margin: "16px auto",
            padding: "16px", 
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)', 
            gap: '16px',
        
            '@media (max-width: 768px)': {
                gridTemplateColumns: '1fr 1fr', 
            },
            '@media (max-width: 480px)': {
                gridTemplateColumns: '1fr 1fr', 
            },
        },
    };

    return (
        <Box sx={styles.container}>
            <Box>
                <StatsCard icon={AvatarIcon} value={Matches} title="Matches"/>
            </Box>
            <Box>
                <StatsCard icon={GraphIcon} value={Runs} title="Runs"/>
            </Box>
            <Box>
                <StatsCard icon={ThunderIcon} value={Wickets} title="Wickets"/>
            </Box>
            <Box>
                <StatsCard icon={AvatarIcon} value={Wins} title="Wins"/>
            </Box>
        </Box>
    );
};

export default PlayerStats;
