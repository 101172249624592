// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  body {
    background: linear-gradient(135deg, #1a1a1a, #333333, #666666);
    color: #fff; /* White text color */
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;8CAC4C;EAC5C,mCAAmC;EACnC,kCAAkC;EAClC,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE;IACE,8DAA8D;IAC9D,WAAW,EAAE,qBAAqB;EACpC;AACF;AACA;EACE,+EAA+E;AACjF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\",\n    \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  min-height: 100vh;\n  overflow-x: hidden;\n}\n@media (min-width: 768px) {\n  body {\n    background: linear-gradient(135deg, #1a1a1a, #333333, #666666);\n    color: #fff; /* White text color */\n  }\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
