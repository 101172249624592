import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const HeadingWithLines = ({ label, lineWidth, Icon }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", my: 1.5 }}>
      <Box sx={{ flex: 1, borderBottom: `${lineWidth} solid #000`, mr: 2 }} />
      <Typography variant="body2" sx={{ textTransform: "uppercase", display: "flex", alignItems: "center" }}>
        {Icon}
        {label}
      </Typography>
      <Box sx={{ flex: 1, borderBottom: `${lineWidth} solid #000`, ml: 2 }} />
    </Box>
  );
};

export default HeadingWithLines;
