import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css/calenderview.css';
import { setSelectedDate, clearGetAllMatches } from '../redux/match/match.slice';

const CalendarView = ({
  setCurrentPage,
  fetchMatchesAction,
  tournamentId, 
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedCardIndex, setSelectedCardIndex] = useState(2); 
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(false); 
  const [calendarData, setCalendarData] = useState([]);
  const dispatch = useDispatch();
  const calendarPickerRef = useRef(null);

  useEffect(() => {
    if (!currentDate || isNaN(currentDate.getTime())) {
      setCurrentDate(new Date());
    }

    const dayBeforeYesterday = moment(currentDate).subtract(2, 'days');
    const yesterday = moment(currentDate).subtract(1, 'days');
    const tomorrow = moment(currentDate).add(1, 'days');
    const dayAfterTomorrow = moment(currentDate).add(2, 'days');

    const currentDayOfWeek = moment(currentDate).format('ddd').toUpperCase();
    const dayBeforeYesterdayOfWeek = dayBeforeYesterday.format('ddd').toUpperCase();
    const yesterdayOfWeek = yesterday.format('ddd').toUpperCase();
    const tomorrowOfWeek = tomorrow.format('ddd').toUpperCase();
    const dayAfterTomorrowOfWeek = dayAfterTomorrow.format('ddd').toUpperCase();

    const newcalendarData = [
      {
        day: dayBeforeYesterdayOfWeek,
        date: formatDate(dayBeforeYesterday, true),
        dispatchDate: formatDate(dayBeforeYesterday),
      },
      {
        day: yesterdayOfWeek,
        date: dateSelected ? formatDate(yesterday, true) : 'Yesterday', 
        dispatchDate: formatDate(yesterday),
      },
      {
        day: currentDayOfWeek,
        date: dateSelected ? formatDate(currentDate, true) : 'Today', 
        dispatchDate: formatDate(currentDate),
      },
      {
        day: tomorrowOfWeek,
        date: dateSelected ? formatDate(tomorrow, true) : 'Tomorrow', 
        dispatchDate: formatDate(tomorrow),
      },
      {
        day: dayAfterTomorrowOfWeek,
        date: formatDate(dayAfterTomorrow, true),
        dispatchDate: formatDate(dayAfterTomorrow),
      },
    ];
    
    setCalendarData(newcalendarData);
    setSelectedCardIndex(2);
  }, [currentDate, dateSelected]);

  const formatDate = (date, forDisplay = false) => {
    if (forDisplay) {
      return moment(date).format('MMM DD').toUpperCase();
    } else {
      return moment(date).format('DD-MM-YYYY');
    }
  };

  const handleDateChange = (date, index) => {
    dispatch(clearGetAllMatches());
    setCurrentPage(1);
    setCurrentDate(date);
    setCalendarOpen(false);
    setDateSelected(true); 
    setSelectedCardIndex(index);
    dispatch(setSelectedDate(formatDate(date)));
    
    if (fetchMatchesAction && tournamentId) {
      dispatch(fetchMatchesAction(tournamentId, formatDate(date))); 
    }
  };

  const handleCalendarCardClick = (date, index) => {
    setCurrentPage(1);
    dispatch(clearGetAllMatches());
    dispatch(setSelectedDate(date));
    setSelectedCardIndex(index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarPickerRef.current && !calendarPickerRef.current.contains(event.target)) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="calendar-page">
      <div className="calendar-container">
        {calendarData.map((item, index) => (
          <div
            key={index}
            className="calendar-item"
            style={{
              backgroundColor: selectedCardIndex === index ? '#f2bd53' : 'transparent',
            }}
            onClick={() => {
              setSelectedCardIndex(index);
              handleCalendarCardClick(item.dispatchDate, index);
            }}
          >
            <p>{item.day}</p>
            <p>{item.date}</p>
          </div>
        ))}
        <CalendarMonthIcon
          sx={{
            fontSize: { xs: '25px', sm: '50px', md: '55px' },
            marginLeft: { xs: '0px', sm: '10px' },
            marginRight: { xs: '0px', sm: '0px' },
            marginTop: { xs: '5px', sm: '5px' },
            cursor: 'pointer',
          }}
          onClick={(event) => {
            event.stopPropagation();
            setCalendarOpen(true);
          }}
        />
      </div>
      {calendarOpen && (
        <div ref={calendarPickerRef} className="calendar-picker-overlay">
          <DatePicker
            selected={currentDate}
            onChange={(date) => handleDateChange(date)}
            onClose={() => setCalendarOpen(false)}
            inline
          />
        </div>
      )}
    </div>
  );
};

export default CalendarView;