import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import gullyLogo from "../assets/gully-logo.svg";


const Header = () => {
    return (
      <AppBar position="sticky" style={{ backgroundColor: '#333' }}>
        <Toolbar>
          <img src={gullyLogo} alt="GullySix Logo" style={{ height: '50px', marginRight: '10px' }} />
        </Toolbar>
      </AppBar>
    );
  };

export default Header;
