import { Box } from '@mui/material';
import React from 'react';
import ProfileAvatar from '../Tournament-Profile/ProfileAvatar';
import Team from '../../assets/Team.jpg'; 

const ProfilePic = ({ profileType, avatarUrl }) => {
    let avatarImage;

    if (profileType === 'team') {
        avatarImage = Team;
    } else if (profileType === 'player') {
        avatarImage = avatarUrl || '';
    }

    return (
        <Box sx={{ mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mx: 2 }}>
                <ProfileAvatar avatar={avatarImage} />
            </Box>
        </Box>
    );
};

export default ProfilePic;
