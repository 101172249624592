import React, { useState } from 'react'
import moment from 'moment'
import Chip from '@mui/material/Chip'
import { Link } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import ShareIcon from '@mui/icons-material/Share'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Box, Typography } from '@mui/material'
import TopPerformer from './TopPerformer'
import { RWebShare } from 'react-web-share'
import InfoModal from './modals/InfoModal'
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import liveAnimation from '../constants/lotties/liveAnimation.json'

const Result = ({ matchData }) => {
  const { topPerformers, isLive, currScore } = useSelector(
    (state) => state.match
  )
  const [open, setOpen] = useState(false)

  const { info, matchId } = matchData[0] || {}
  const { date, numOfPlayerInEachTeam, limitedOver, result, innings } =
    info?.[0] || {}
  const matchDate = moment(date).utc().format('dddd DD MMMM YYYY, h:mm A')
  const format = `Gully ${numOfPlayerInEachTeam}X${limitedOver}`
  const resultData = result
  const handleOpen = () => setOpen(true)
  const battingTeam = currScore?.battingTeam
  const firstInning = currScore?.showFirstInningScore
  const teamName1 = matchData?.[0]?.info?.[0]?.teams?.[0]?.teamName
  const teamName2 = matchData?.[0]?.info?.[0]?.teams?.[1]?.teamName
  const showScoreCardTeam1 = currScore?.teamScore?.[teamName1]?.showScoreCard
  const showScoreCardTeam2 = currScore?.teamScore?.[teamName2]?.showScoreCard
  const isBattingTeam = (teamName) => {
    return isLive && battingTeam === teamName
  }
  const getOversPlayedByTeam = (teamName) => {
    return (
      innings?.find(({ battingTeam }) => battingTeam === teamName)
        ?.inningOver || 0
    )
  }

  return (
    <>
      <InfoModal setOpen={setOpen} open={open} matchId={matchId} />
      <Box sx={{ width: '100%', bgcolor: 'black', color: '#fff', pt: 1 }}>
        <Box
          sx={{
            px: 2,
            color: '#808080',
            mb: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: { sm: '16px', xs: '10px' }, mr: 5, flex: 1 }}
          >
            {matchDate}
          </Typography>
          <Box sx={{ display: 'flex', columnGap: 2 }}>
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "1px" }}>
              <FavoriteBorderIcon sx={{ fontSize: "24px" }} /> <Typography variant="body2">0</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "1px" }}>
              <RemoveRedEyeIcon sx={{ fontSize: "24px" }} /> <Typography variant="body2">0</Typography>
            </Box> */}
            {/* <FavoriteIcon  sx={{ fontSize: '24px', cursor: 'pointer'}}/>
             <VisibilityIcon  sx={{ fontSize: '24px', cursor: 'pointer'}}/> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <RWebShare
                data={{
                  text: 'Lets Watch together',
                  url: window.location,
                  title: 'Gully Cricket',
                }}
              >
                <ShareIcon sx={{ fontSize: '24px', cursor: 'pointer' }} />
              </RWebShare>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <InfoIcon
                sx={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => handleOpen(true)}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              mx: 2,
              display: 'flex',
              pb: 2.5,
              borderBottom: '1px solid #D6D5D566',
            }}
          >
            <Box
              sx={{
                textAlign: 'left',
                width: '9rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#ffffff',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  color: '#ff6161',
                  fontSize: '2.3vh',
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'clip',
                  '@media screen and (max-width: 600px)': {
                    textOverflow: 'ellipsis',
                    fontSize: '2.3vh',
                  },
                }}
              >
                <Link
                  to={`/team/${matchData[0].info[0].teams[0].teamObjectId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {matchData[0]?.info[0]?.teams[0]?.teamName}
                </Link>
              </Box>
              {resultData ? (
                showScoreCardTeam1 ? (
                  <Box
                    sx={{
                      color: 'rgb(255, 255, 255)',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {isLive
                      ? currScore['teamScore'][
                          matchData[0].info[0].teams[0].teamName
                        ]['runs'] +
                        '/' +
                        currScore['teamScore'][
                          matchData[0].info[0].teams[0].teamName
                        ]['wickets']
                      : `${resultData[0]['t1i1_score']}/${resultData[0]['t1i1_wicket']}`}
                  </Box>
                ) : (
                  <Box sx={{ fontSize: '14px' }}>YET TO BAT</Box>
                )
              ) : (
                <Box sx={{ fontSize: '14px' }}>0 / 0</Box>
              )}

              <Box sx={{ fontSize: '14px' }}>
                {isLive
                  ? (currScore['teamScore'][
                      matchData[0].info[0].teams[0].teamName
                    ]['overs'] || '0.0') +
                    '/' +
                    `${limitedOver} ov`
                  : `${getOversPlayedByTeam(
                      matchData[0].info[0].teams[0].teamName
                    )}/${limitedOver} ov`}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xs: '200px', sm: 'auto' },
              }}
            >
              <Chip
                label={format}
                sx={{
                  backgroundColor: '#1F67FF',
                  borderRadius: '2px',
                  color: '#FFFFFF',
                  fontSize: '0.7rem',
                  mb: '8px',
                  height: '20px',
                }}
              />
              {resultData && (
                <>
                  {firstInning ? (
                    <Typography
                      sx={{
                        color: '#F2D875',
                        textAlign: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      {currScore?.firstInningScore}
                    </Typography>
                  ) : (
                    <>
                      {resultData[0]?.result_type && (
                        <Typography
                          sx={{
                            color: '#F2D875',
                            textAlign: 'center',
                            overflow: 'hidden',
                          }}
                        >
                          {resultData[0].result_type}
                        </Typography>
                      )}
                      {isLive && (
                        <>
                          {currScore['target']?.['uiTargetText'] && (
                            <Typography
                              sx={{ color: '#F2D875', textAlign: 'center' }}
                            >
                              {currScore['target']['uiTargetText']}
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                width: '9rem',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#ffffff',
                flex: 1,
              }}
            >
              <Box
                sx={{
                  color: '#9a7bf8',
                  fontSize: '2.3vh',
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'clip',
                  '@media screen and (max-width: 600px)': {
                    textOverflow: 'ellipsis',
                    fontSize: '2.3vh',
                  },
                }}
              >
                <Link
                  to={`/team/${matchData[0].info[0].teams[1].teamObjectId}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {matchData[0]?.info[0]?.teams[1]?.teamName}
                </Link>
              </Box>
              {resultData ? (
                showScoreCardTeam2 ? (
                  <Box
                    sx={{
                      color: 'rgb(255, 255, 255)',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {isLive
                      ? currScore['teamScore'][
                          matchData[0].info[0].teams[1].teamName
                        ]['runs'] +
                        '/' +
                        currScore['teamScore'][
                          matchData[0].info[0].teams[1].teamName
                        ]['wickets']
                      : `${resultData[0]['t2i1_score']}/${resultData[0]['t2i1_wicket']}`}
                  </Box>
                ) : (
                  <Box sx={{ fontSize: '14px' }}>YET TO BAT</Box>
                )
              ) : (
                <Box sx={{ fontSize: '14px' }}>0 / 0</Box>
              )}

              <Box sx={{ fontSize: '14px' }}>
                {isLive
                  ? (currScore['teamScore'][
                      matchData[0].info[0].teams[1].teamName
                    ]['overs'] || '0.0') +
                    '/' +
                    `${limitedOver} ov`
                  : `${getOversPlayedByTeam(
                      matchData[0].info[0].teams[1].teamName
                    )}/${limitedOver} ov`}
              </Box>
            </Box>
          </Box>
        </Box>

        {topPerformers && !isLive ? (
          <Box>
            <TopPerformer topPerformers={topPerformers} />
          </Box>
        ) : null}
      </Box>
    </>
  )
}

export default Result
