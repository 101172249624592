import { Avatar, Box } from "@mui/material";
import React from "react";

const ProfileAvatar = ({ avatar }) => {
  return (
    <Box
      sx={{
        mt: 1,
        width: "70px",
        height: "70px",
        overflow: "hidden",
        borderRadius: "50%",
        position: "relative",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(181,150,9,1) 35%, rgba(0,212,255,1) 100%)",
        boxShadow: "0 0 0 2px transparent",
      }}
    >
      <Avatar
        alt="Avatar Here"
        src={avatar}
        sx={{
          width: "64px",
          height: "64px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "2px solid white",
        }}
      />
    </Box>
  );
};

export default ProfileAvatar;
