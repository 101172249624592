import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";

const ActionsMenu = ({ onVisitPage, onViewInfo }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleVisitPage = (params) => {
  //   onVisitPage(params);
  // };

  const handleViewInfo = (params) => {
    onViewInfo(params);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem onClick={handleVisitPage}>Visit Match Page</MenuItem> */}
        <MenuItem onClick={handleViewInfo}>View Match Json</MenuItem>
      </Menu>
    </div>
  );
};

export default ActionsMenu;
