import React, { useState } from 'react'
import { Button, Box, Typography } from '@mui/material'
import ScoreTable from './ScoreTable'

const ScoreInningTabs = ({ inningsData, teamData }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleButtonClick = (index) => {
    setActiveTab(index)
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        marginBottom={3}
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          paddingX: 1,
        }}
      >
        {inningsData.map((inning, index) => (
          <Button
            key={index}
            onClick={() => handleButtonClick(index)}
            sx={{
              backgroundColor: index === activeTab ? 'black' : '#F3F3F3',
              color: index === activeTab ? 'white' : 'black',
              borderRadius: 2,
              minWidth: '150px',
              marginX: 1,
              '&:hover': {
                backgroundColor: index === activeTab ? 'black' : '#F3F3F3',
              },
            }}
          >
            <Typography
              sx={{
                color: index === activeTab ? '#ffffff' : '#000000',
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '@media screen and (max-width: 600px)': {
                  maxWidth: '60%',
                },
              }}
            >
              {inning.battingTeam}
            </Typography>
          </Button>
        ))}
      </Box>
      <Box pb={2}>
        <ScoreTable inning={inningsData[activeTab]} />
      </Box>
    </Box>
  )
}

export default ScoreInningTabs
