import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

export default function CustomTabs({ value, setValue, tabs }) {
  const changeTab = (newValue) => {
    setValue(newValue)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      height="100%"
      marginBottom={1}
      marginTop={0}
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        paddingX: 0.5,
        backgroundColor: 'black',
      }}
    >
      {tabs.map(
        (tab) =>
          tab.isVisible && (
            <Button
              key={tab.value}
              sx={{
                flex: 1,
                backgroundColor: value === tab.value ? 'black' : 'black',
                color: value === tab.value ? 'yellow' : 'white',
                borderBottom: value === tab.value ? '4px solid yellow' : 'none',
                borderRadius: value === tab.value ? '8px 8px 0px 0px' : 5,
                '&:hover': {
                  backgroundColor: value === tab.value ? 'black' : 'black',
                },
              }}
              onClick={() => changeTab(tab.value)}
            >
              {tab.label}
            </Button>
          )
      )}
    </Box>
  )
}
