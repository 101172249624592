import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  calculateDotBalls,
  convertBallsToOver,
  fetchWicketFall,
} from '../helpers/calculationLogics'

const ScoreTable = ({ inning, isLive, teamScore, currOver }) => {
  const matchData = useSelector((state) => state.match)
  let {
    batsManDetail,
    bowlerDetail,
    inningExtraRuns,
    overDetail,
    totalWideBall,
    totalNoBall,
    totalByeRuns,
  } = isLive ? teamScore : inning
  const playerDotBallsDetails = isLive
    ? teamScore?.bowlerDotBalls
    : calculateDotBalls(overDetail)
  const ballPerOver = matchData?.data?.[0].info?.[0]?.numOfBallsPerOver || 0
  const wicketFallDetails = isLive
    ? teamScore?.fallOfWickets
    : fetchWicketFall(overDetail)
  const overDetails = isLive
    ? `(${teamScore?.inningOver} ov)`
    : inning?.inningOver
    ? `(${inning?.inningOver} ov)`
    : null
  const showScoreCard = !!inning ? true : teamScore?.showScoreCard
  if (isLive) {
    batsManDetail =
      showScoreCard && batsManDetail.length > 0
        ? batsManDetail
        : teamScore?.players.map((player) => ({
            batsmanName: player.playername,
            batsmanRun: '-',
            batsmanBall: '-',
            batsmanFour: '-',
            batsmanSix: '-',
          }))
  }
  return (
    <>
      <Accordion sx={{ my: 1, bgcolor: '#F3F3F3' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="batsman-content"
          id="batsman-header"
          sx={{
            '.css-o4b71y-MuiAccordionSummary-content': {
              m: 0,
            },
          }}
        >
          <Typography variant="body2">BATTER DETAILS</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <TableContainer component={Paper}>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead sx={{ backgroundColor: 'black !important' }}>
                <TableRow>
                  <TableCell
                    sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                  >
                    Batter
                  </TableCell>
                  <TableCell
                    sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                  >
                    R
                  </TableCell>
                  <TableCell
                    sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                  >
                    B
                  </TableCell>
                  <TableCell
                    sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                  >
                    4s
                  </TableCell>
                  <TableCell
                    sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                  >
                    6s
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batsManDetail.map(
                  (batsman, index) =>
                    batsman?.batsmanName && (
                      <TableRow key={index} sx={{ border: 'none' }}>
                        <TableCell
                          sx={{
                            border: 'none',
                            // currentStrikerIndicator uncomment once implementation from flutter & backend is complete
                            // position: 'relative',
                            // '::before': {
                            //   content: '""',
                            //   position: 'absolute',
                            //   top: 0,
                            //   bottom: 0,
                            //   left: 0,
                            //   width: '4px',
                            //   backgroundColor:
                            //     isLive &&
                            //     teamScore.currentStriker === batsman.batsmanName
                            //       ? 'red'
                            //       : 'transparent',
                            // },
                          }}
                        >
                          <Link
                            to={`/player/${batsman.batsmanName}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {batsman.batsmanName}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                          {batsman.batsmanRun}
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                          {batsman.batsmanBall}
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                          {batsman.batsmanFour}
                        </TableCell>
                        <TableCell sx={{ border: 'none' }}>
                          {batsman.batsmanSix}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              py: 1,
              bgcolor: '#E2E8F0',
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Total Score
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography variant="body2" fontWeight={600}>
                {teamScore?.inningScore}
              </Typography>
              {overDetails ? (
                <Typography variant="body2" fontWeight={600}>
                  {overDetails}
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Card sx={{ py: 1 }}>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                Total Extras
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                Extra Runs - {inningExtraRuns} | W {totalWideBall || 0} | NB{' '}
                {totalNoBall || 0} | B{' '} {totalByeRuns || 0}
              </Typography>
            </Container>
          </Card>
        </AccordionDetails>
      </Accordion>
      {showScoreCard && (
        <>
          <Accordion sx={{ my: 3, bgcolor: '#F3F3F3' }} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="bowler-content"
              id="bowler-header"
              sx={{
                '.css-o4b71y-MuiAccordionSummary-content': {
                  m: 0,
                },
              }}
            >
              <Typography variant="body2">BOWLER DETAILS</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <TableContainer component={Paper}>
                <Table sx={{ borderCollapse: 'collapse' }}>
                  <TableHead sx={{ backgroundColor: 'black !important' }}>
                    <TableRow>
                      <TableCell
                        sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                      >
                        Bowler
                      </TableCell>
                      <TableCell
                        sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                      >
                        B
                      </TableCell>
                      <TableCell
                        sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                      >
                        R
                      </TableCell>
                      <TableCell
                        sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                      >
                        W
                      </TableCell>
                      <TableCell
                        sx={{ color: '#FFFFFF', backgroundColor: 'black' }}
                      >
                        Db
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bowlerDetail.map(
                      (bowler, index) =>
                        bowler?.bowlerName && (
                          <TableRow key={index} sx={{ border: 'none' }}>
                            <TableCell sx={{ border: 'none' }}>
                              <Link
                                to={`/player/${bowler.bowlerName}`}
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                {bowler.bowlerName}
                              </Link>
                            </TableCell>
                            <TableCell sx={{ border: 'none' }}>
                              {bowler.bowlerBall}
                            </TableCell>
                            <TableCell sx={{ border: 'none' }}>
                              {bowler.bowlerRun}
                            </TableCell>
                            <TableCell sx={{ border: 'none' }}>
                              {bowler.bowlerWkt}
                            </TableCell>
                            <TableCell sx={{ border: 'none' }}>
                              {playerDotBallsDetails?.[bowler.bowlerName] || 0}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ my: 3, bgcolor: '#F3F3F3' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="extra-runs-content"
              id="extra-runs-header"
              sx={{
                '.css-o4b71y-MuiAccordionSummary-content': {
                  m: 0,
                },
              }}
            >
              <Typography variant="body2">FALL OF WICKETS</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Card sx={{ display: 'flex', px: 2, py: 1, flexWrap: 'wrap' }}>
                {wicketFallDetails && wicketFallDetails.length ? (
                  wicketFallDetails.map((wicket, index) => (
                    <Typography variant="body2" key={index}>
                      {`${wicket.currentRun} (${
                        wicket.batsman
                      } ${convertBallsToOver(
                        wicket.ballCount,
                        Number(ballPerOver)
                      )} ov) ${
                        index === wicketFallDetails.length - 1 ? '' : ','
                      }`}
                    </Typography>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ textAlign: 'center', width: '100%' }}
                  >
                    No Details To Show
                  </Typography>
                )}
              </Card>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  )
}

export default ScoreTable
