import React from "react";
import { Box, Card, Container, Typography, Divider, useMediaQuery } from "@mui/material";
import GoldBadge from "../../assets/Badge.svg";

const commonTypographyStyle = { fontSize: { sm: "16px", xs: "10px", color: "#D5D5D5" } };
const cardStyle = { bgcolor: "#2B2B3D", color: "white", borderRadius: "12px", p: 2 }; 
const flexContainerStyle = { display: "flex", justifyContent: "center", alignItems: "center", gap: 1, mt: 1 };
const borderBottomStyle = { pb: 1, borderBottom: "1px solid black" };
const centralizedStyle = { display: "flex", flexDirection: "column", alignItems: "center" };
const goldBadgeStyle = { width: 32, height: 32 };

const TopPerformerCard = ({ badgeIcon, badgeText, playerName, teamName, runs, strikeRate, fours, sixes }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Card sx={cardStyle}>
      <Container maxWidth="md"> 
        <Box sx={{ mx: "auto", py: 0.5, borderBottom: "1px solid #CBCBCB" }}> 
          <Box sx={{ display: "flex", justifyContent: "center", gap: 3, my: 1 }}>
            <Box component="img" src={GoldBadge} alt="badge" sx={{ mt: "2px" }} />
            <Typography>{badgeText}</Typography>
          </Box>
          <Box sx={{ ...flexContainerStyle, ...borderBottomStyle, justifyContent: "space-between" }}>
            <Typography>{playerName}</Typography>
            <Typography sx={commonTypographyStyle}>{teamName}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-evenly", my: 2 }}>
            <Box sx={centralizedStyle}>
              <Typography>{runs}</Typography>
              <Typography sx={commonTypographyStyle}>Run</Typography>
            </Box>
            <Box sx={centralizedStyle}>
              <Typography>{strikeRate}</Typography>
              <Typography sx={commonTypographyStyle}>Strike Rate</Typography>
            </Box>
            <Box sx={centralizedStyle}>
              <Typography>{fours}</Typography>
              <Typography sx={commonTypographyStyle}>Fours</Typography>
            </Box>
            <Box sx={centralizedStyle}>
              <Typography>{sixes}</Typography>
              <Typography sx={commonTypographyStyle}>Sixes</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Card>
  );
};

const MVPCard = ({ badgeIcon, badgeText, playerName, teamName, Points, runs, strikeRate, wickets, economy }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const roundToOneDecimal = (value) => {
    return Number.isInteger(value) ? value : value.toFixed(0);
  };

  const roundToThousand = (value) => {
    if (value < 1) {
      return Math.floor(value * 1000);
    } else {
      return Math.floor(value);
    }
  };
  
  return (
    <Card sx={{ ...cardStyle, fontSize: isMobile ? "14px" : "inherit" }}>
      <Container maxWidth="md">
      <Box sx={{ mx: "auto", py: 0.5, borderBottom: "1px solid #CBCBCB" }}> 
        <Box sx={{ mx: "auto", py: 0.5 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Box component="img" src={GoldBadge} alt="badge" sx={{ ...goldBadgeStyle, mt: "0px" }} />
              <Typography sx={{ ...commonTypographyStyle, textTransform: "uppercase", fontSize: "18px" }}>{badgeText}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              ...flexContainerStyle,
              flexDirection: isMobile ? "row" : "row",
              justifyContent: "space-between",
              mt: isMobile ? "10px" : "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Typography
                sx={{
                  ...commonTypographyStyle,
                  fontSize: isMobile ? "14px" : "20px",
                  textTransform: "uppercase",
                  textAlign: "left",
                  marginBottom: "5px",
                }}
              >
                {playerName}
              </Typography>
              <Typography
                sx={{
                  ...commonTypographyStyle,
                  fontSize: isMobile ? "14px" : "18px",
                  color: "red",
                  textTransform: "uppercase",
                  textAlign: "left",
                }}
              >
                {teamName}
              </Typography>
            </Box>
            <Box sx={{ bgcolor: "#FFD700", borderRadius: "8px", px: 2, py: 0.5 }}>
              <Typography
                sx={{
                  ...commonTypographyStyle,
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  color: "white",
                  fontSize: isMobile? "14px" : "16px",
                  textAlign: "center",
                }}
              >
                {roundToThousand(Points)}
              </Typography>
              <Typography
                sx={{
                  ...commonTypographyStyle,
                  color: "white",
                  fontWeight: "bold",
                  color: "white",
                  fontSize: isMobile? "14px" : "16px",
                }}
              >
                POINTS
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ bgcolor: "black", height: 3, my: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "space-evenly", my: 2 }}>
            <Box sx={centralizedStyle}>
              <Typography>{runs}</Typography>
              <Typography sx={commonTypographyStyle}>Runs</Typography>
            </Box>
            <Box sx={centralizedStyle}>
              <Typography>{roundToOneDecimal(strikeRate)}</Typography>
              <Typography sx={commonTypographyStyle}>S/R</Typography>
            </Box>
            <Box sx={centralizedStyle}>
              <Typography>{wickets}</Typography>
              <Typography sx={commonTypographyStyle}>W</Typography>
            </Box>
            <Box sx={centralizedStyle}>
              <Typography>{roundToOneDecimal(economy)}</Typography>
              <Typography sx={commonTypographyStyle}>Eco</Typography>
            </Box>
          </Box>
        </Box>
        </Box>
      </Container>
    </Card>
  );
};

export { TopPerformerCard, MVPCard };
