import React, { useState, useEffect } from 'react';
import './css/livewinprob.css';

const LiveWinProbabilityPredictor = () => {
  const [winProbability, setWinProbability] = useState(50);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newWinProbability = Math.floor(Math.random() * 101);
      setWinProbability(newWinProbability);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  
  const calculateLineColors = () => {
    if (winProbability === 50) {
      return ['white 50%', 'white 50%'];
    }
    const leftColor = winProbability >= 50 ? '#41FC03' : 'white'; 
    const rightColor = winProbability >= 50 ? 'white' : '#41FC03';
    return [`${leftColor} ${winProbability}%`, `${rightColor} ${winProbability}%`];
  };
  
  const getProbabilityColor = (probability) => {
    return probability > 50 ? '#41FC03' : 'white'; 
  };

  return (
    <div className="live-win-probability-predictor">
      <h5 style={{ marginBottom: '50px' }}>LIVE WIN PROBABILITY</h5>
      <div className="win-probability-design">
        <div className="line-container">
          <div
            className="line"
            style={{
              background: `linear-gradient(to right, ${calculateLineColors()[0]}, ${calculateLineColors()[1]})`
            }}
          />
        </div>
        <div className="team left">
          <p> Team A</p>
          <p style={{ color: getProbabilityColor(winProbability) }}>
            {winProbability !== null ? `${winProbability}%` : 'Calculating...'}
          </p>
        </div>
        <div className="team right">
          <p> Team B</p>
          <p style={{ color: getProbabilityColor(100 - winProbability) }}>
            {winProbability !== null ? `${100 - winProbability}%` : 'Calculating...'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LiveWinProbabilityPredictor;
