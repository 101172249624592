import axios from 'axios'
import moment from 'moment'
import {
  setCurrentPlayingVideo,
  setIndividualHighlightClipsList,
  setSummaryAndFullMatchVideosList,
} from '../video/video.slice'
import {
  setMatchData,
  setMatchError,
  setMatchLoading,
  setTotalMatches,
  setTeamData,
  setTeamLoading,
  setTeamError,
  setSelectedDate,
  setPlayerLoading,
  setPlayerData,
  setPlayerError,
} from './match.slice'

export const fetchMatchData = (matchId, getTopPerformersFlag) => {
  return async (dispatch) => {
    try {
      console.log('Fetching match data from server...')
      dispatch(setMatchLoading())
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/match/${matchId}?getTopPerformers=${getTopPerformersFlag}`
      )
      const summaryVideo =
        response.data?.data?.matchMedias?.fullAndSummaryVideos?.find(
          (video) => video.videoCategory === 'HIGLIGHT_SUMMARY'
        )
      const fullMatchVideo =
        response.data?.data?.matchMedias?.fullAndSummaryVideos?.find(
          (video) => video.videoCategory === 'FULL_MATCH_VIDEO'
        )
      const initVideoToPlay =
        fullMatchVideo && !summaryVideo
          ? { url: fullMatchVideo?.mediaUrl, category: 'FULL_MATCH_VIDEO' }
          : { url: summaryVideo?.mediaUrl, category: 'HIGLIGHT_SUMMARY' }
      dispatch(setMatchData(response.data.data))
      dispatch(
        setSummaryAndFullMatchVideosList(
          response.data?.data?.matchMedias?.fullAndSummaryVideos
        )
      )
      dispatch(
        setIndividualHighlightClipsList(
          response.data?.data?.matchMedias?.individualHighlightClips
        )
      )
      dispatch(setCurrentPlayingVideo(initVideoToPlay))
    } catch (error) {
      dispatch(setMatchError(error.message))
    }
  }
}

export const fetchTeamData = (teamObjectId) => {
  return async (dispatch) => {
    try {
      console.log('Fetching team data from server...')
      dispatch(setTeamLoading())
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/team/details?teamId=${teamObjectId}`
      )
      dispatch(setTeamData(response.data.data.teamDetails))
    } catch (error) {
      dispatch(setTeamError(error.message))
    }
  }
}

export const fetchPlayerData = (userName) => {
  return async (dispatch) => {
    try {
      console.log('Fetching Player data from server...')
      dispatch(setPlayerLoading())
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/user-profile?userName=${userName}`
      )
      const playerData = response.data.data.profileData
      const userObjId = playerData.userObjId._id
      dispatch(setPlayerData({ ...playerData, userObjId }))
    } catch (error) {
      dispatch(setPlayerError(error.message))
    }
  }
}

export const fetchPlayerMatches = (currentPage, pageSize, userObjId) => {
  return async (dispatch) => {
    try {
      dispatch(setMatchLoading())

      let url = `${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/matches?page=${currentPage}&pageSize=${pageSize}&userObjId=${userObjId}`

      const response = await axios.get(url)
      dispatch(setTotalMatches(response.data.data))
    } catch (error) {
      dispatch(setMatchError(error.message))
    }
  }
}

export const fetchAllMatches = (currentPage, pageSize, date) => {
  return async (dispatch) => {
    try {
      dispatch(setMatchLoading())

      let url = `${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/matches?page=${currentPage}&pageSize=${pageSize}`

      if (date) {
        url += `&startDate=${date}`
      }

      const response = await axios.get(url)

      dispatch(setTotalMatches(response.data.data))
    } catch (error) {
      dispatch(setMatchError(error.message))
    }
  }
}
