import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom'
import MatchContainer from './pages/cricketMatch/matchContainer'
import MatchList from './pages/cricketMatch/matchList'
import MatchJsonViewPage from './pages/cricketMatch/matchListPage/match.jsonView'
import MatchesTablePage from './pages/cricketMatch/matchListPage/matches.page'
import ProfileContainer from './pages/ProfilePage/ProfileContainer'
import TermsAndConditions from './pages/policies/termsAndConditions'
import PrivacyPolicy from './pages/policies/privacyPolicy'
import DeleteAccount from './pages/policies/deleteAccount'
import DeleteData from './pages/policies/deleteData'
import AboutUsPage from './pages/policies/AboutUs'
import TeamProfilePage from './components/Team-Profile/TeamProfile'
import PlayerProfilepage from './components/Player-Profile/PlayerProfile';

const App = () => {
  ReactGA.initialize('G-KTXRWK4S3C')

  const TrackPageViews = () => {
    const location = useLocation()

    useEffect(() => {
      ReactGA.pageview(location.pathname + location.search)
    }, [location])

    return null
  }
  return (
    <Router>
      <Routes>
        <Route element={<TrackPageViews />} />
        <Route exact path="/" element={<MatchList />} />
        <Route exact path="/match/:matchId" element={<MatchContainer />} />
        <Route
          exact
          path="/match/internal/info/:matchId"
          element={<MatchJsonViewPage />}
        />
        <Route exact path="/internal/matches" element={<MatchesTablePage />} />
        <Route
          exact
          path="/tournament-profile/:tournamentId"
          element={<ProfileContainer />}
        />
        <Route exact path="/privacy-policies" element={<PrivacyPolicy />} />
        <Route
          exact
          path="/terms-of-service"
          element={<TermsAndConditions />}
        />
        <Route exact path="/delete-account" element={<DeleteAccount />} />
        <Route exact path="/delete-data" element={<DeleteData />} />
        <Route exact path="/about-us" element={<AboutUsPage />} />
        <Route exact path="/team/:teamObjectId" element={<TeamProfilePage />} />
        <Route exact path="/player/:userName" element={<PlayerProfilepage />} />
      </Routes>
    </Router>
  )
}
export default App
