export const pointData = [
  { label: "P", explanation: "Played" },
  { label: "W", explanation: "Won" },
  { label: "L", explanation: "Loss" },
  { label: "T", explanation: "Tie" },
  { label: "NRR", explanation: "Net Run Rate" },
  { label: "Pts", explanation: "Points" },
];

export const pointsValue = [
  { label: "2 points", explanation: "Won" },
  { label: "1 point", explanation: "Tie" },
  { label: "0 point", explanation: "Loss" },
];
