// PrivacyPolicies.js
import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          fontFamily: "Arial, sans-serif",
          backgroundColor: "#f4f4f4",
          margin: 0,
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        //   height: "100vh",
        }}
      >
        <Typography paragraph>
          <>
            <p style={{ textAlign: "center" }}>
              <strong>
                <u>
                  <span style={{ fontSize: "12pt" }}>PRIVACY POLICY&nbsp;</span>
                </u>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "12pt" }}>The website</span>
              <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                &nbsp;
              </span>
              <strong>
                <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                  “gully6.com”
                </span>
              </strong>
              <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                &nbsp;and&nbsp;
              </span>
              <strong>
                <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                  “Gully6 Application”
                </span>
              </strong>
              <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                , (collectively referred to as&nbsp;
              </span>
              <strong>
                <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                  “Platform”)
                </span>
              </strong>
              <span style={{ backgroundColor: "#ffffff", fontSize: "12pt" }}>
                &nbsp;which is&nbsp;
              </span>
              <span style={{ fontSize: "12pt" }}>
                owned and operated by Myy Sports Private Limited (referred as
              </span>
              <strong>
                <span style={{ fontSize: "12pt" }}>&nbsp;“Gully6”)</span>
              </strong>
              <span style={{ fontSize: "11pt" }}>
                &nbsp;is committed to the privacy of its users&nbsp;
              </span>
              <strong>
                <span style={{ fontSize: "12pt" }}>
                  (“you” or “your” or “users”)
                </span>
              </strong>
              <span style={{ fontSize: "11pt" }}>.</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                As a user of the Gully6.com website and related services
                (collectively, the “Services”), we
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                know you want to remain in control of your personal information.
                We realize that earning and
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                retaining your trust is of paramount importance and it's a
                responsibility we take very seriously,
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>every day.</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                That's why we have provided this Privacy Policy, which sets
                forth our policies regarding the
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                collection, use and protection of the personal information of
                those using our Services in
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                accordance with applicable law, including the General Data
                Protection Regulation (“GDPR”).
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                This Privacy Policy inter alia explains the following:
              </span>
            </p>
            <ul>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    What personal information we collect from you and on what
                    legal basis we may use it and how we use it
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    With whom (if anyone) your personal information is shared
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Your rights regarding our processing of your personal data
                    and how you can opt-out of receiving our promotional emails
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    What safeguards we have in place to protect your personal
                    information
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Other information you should know about your privacy
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Personal Information</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Personal information or personal data means information that can
                be linked to a specific individual (“Personal Data”), such as
                name, address, telephone number, e-mail address and credit card
                number. This Privacy Policy explains how Gully6 collects, uses
                and discloses information from and about you when you use any of
                the Services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We encourage you to review our Privacy Policy, and become
                familiar with it, but you should know that we do not sell or
                rent our user's personal information to third parties.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Please note that we review our Privacy Policy from time to time,
                and we may make periodic changes to the policy in connection
                with that review. Therefore, you may wish to bookmark this page
                and/or periodically review this page to make sure you have the
                latest version.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Legal Basis</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We process your Personal Data only based on one or more of the
                following processing grounds as listed in the GDPR:
              </span>
            </p>
            <ul>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>Your consent;</span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    The performance of a contract to which you are a party;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    The protection of your vital interests or those of another
                    natural person;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    Our legitimate interests or those of a third party; and/or
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    The compliance with legal obligations to which we are
                    subject.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Consent</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Where our processing activities rely on your consent, such
                consent can always be withdrawn at
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                any time. Withdrawal of your consent does not affect the
                lawfulness of processing based on
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                consent before the consent was withdrawn.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                With regard to children who are below the age of 16, the consent
                given is only valid if it is given or authorized by the holder
                of parental responsibility over the child. We shall make
                reasonable efforts to verify consent is given or authorized by
                the holder of parental responsibility over the child, for
                example by asking the age of the data subject and to state they
                have been granted adequate authorization to give their consent.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Processing purposes</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We may process your personal information for the following
                purposes:
              </span>
            </p>
            <ul>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to provide our Services;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to broadcast livestream content;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to send you information which you requested;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to perform and manage your orders for products and/or
                    services offered by us;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to manage your account;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to send you information about new features or new products
                    or services that may be relevant for you based on Gully6’
                    products and services previously purchased by you;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to better understand the visitors and users of our Services
                    and what interests them;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to improve the quality and relevance of our Services for our
                    visitors, for example, to customize your user experience,
                    tailor your searches and show you advertisements that may be
                    of interest;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to analyze your use of products and services made available
                    by us for quality control, product development and marketing
                    purposes;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to organize joint marketing events with third parties;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to prevent and detect fraud;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to protect us in the event of a legal dispute;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>to train our staff;</span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to comply with statutory obligations imposed on us, such as
                    obligations regarding company administration and tax law;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to respond to enforcement requests;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to protect our rights and interests, or those of our users
                    or third parties
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to display third party content;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to process your information requests;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to test the performance and features of our Services;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to manage your registration and authentication (either
                    directly with us, or through third parties); and
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    for other purposes we have made known to you beforehand.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>
                  Types of information we may process
                </span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We may collect your Personal Data in the following manner(s):
              </span>
            </p>
            <ul>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    your portrait and body as a whole, when we make recordings
                    of a sporting match in which you participated and your
                    consent was given to do so;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    your first name, last name, contact details (address, e-mail
                    address and telephone number), date of birth, gender and
                    billing information when you create an account for the use
                    of our Services;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    your account, your profile image, contact details, the
                    matches you viewed, users you followed, videos you uploaded,
                    social posts you created, replied and liked, conten reports
                    and your team and tournament ownership to measure your user
                    activity;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    the data made available by you in connection to an account
                    for the use of our Services. If you do not provide us with
                    certain Personal Data, we will not be able to provide (part
                    of) our Services to you that require an account;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    the data we automatically collect from your device, which
                    may include your IP address, device type, unique device
                    identification numbers, browser-type (such as Firefox,
                    Safari, or Internet Explorer), your Internet Service
                    Provider (ISP), your operating system and carrier, technical
                    information which includes the way your device interacts
                    with our website, details of any referring website or exit
                    pages as well as broad geographic location;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    we may receive your Personal Data from third parties or
                    affiliates, which includes social media providers. Such data
                    includes name, profile picture, gender, birthday, email
                    address, town or district and any other information you have
                    chosen to make available. We do not store or use any data
                    related to the friends you are connected with on a social
                    media site. We may also access information from social media
                    providers about your use of an app that we run on their
                    website;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    when you call our team or receive a call from our team, the
                    call may be recorded or monitored; and
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    when you contact us through our Services, for example via
                    chat, the messages will be recorded and monitored.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                In the table below we have included a non-exhaustive list of the
                types of Personal Data we
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                currently collect and process:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <div align="left">
              <table style={{ border: "none", borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ backgroundBorder: "solid #000000 1pt" }}>
                      <p>
                        <strong>
                          <span style={{ color: "black", fontSize: "11pt" }}>
                            Purpose
                          </span>
                        </strong>
                      </p>
                    </td>
                    <td style={{ backgroundBorder: "solid #000000 1pt" }}>
                      <p>
                        <strong>
                          <span style={{ color: "black", fontSize: "11pt" }}>
                            Personal data
                          </span>
                        </strong>
                      </p>
                    </td>
                    <td style={{ backgroundBorder: "solid #000000 1pt" }}>
                      <p>
                        <strong>
                          <span style={{ color: "black", fontSize: "11pt" }}>
                            Processing grounds
                          </span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          Analytics&nbsp;
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <ul>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>Usage data</span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Device information
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Geographical data
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Device identifiers
                            </span>
                          </p>
                        </li>
                      </ul>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          Our legitimate interest to analyze users’ behaviour in
                          order to further improve our Services.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>User contact;</span>
                      </p>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <ul>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              First and last name
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Home address (street, zip code, city, country)
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Email address
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Phone number
                            </span>
                          </p>
                        </li>
                      </ul>
                      <br />
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          Your consent when you submit the personal data using a
                          contact form to get in contact with us; The
                          performance of our agreement with you.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          Registration and authentication
                        </span>
                      </p>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <ul>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              First and last name
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Email address
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Phone number
                            </span>
                          </p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>
                              Social media account
                            </span>
                          </p>
                        </li>
                      </ul>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          Your consent when you use a third party other than us
                          to provide authentication services; The performance of
                          our agreement with you.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>Marketing</span>
                      </p>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <ul>
                        <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                          <p>
                            <span style={{ fontSize: "11pt" }}>Usage data</span>
                          </p>
                        </li>
                      </ul>
                    </td>
                    <td style={{ border: "solid #000000 1pt" }}>
                      <p>
                        <span style={{ fontSize: "11pt" }}>
                          Your consent when you opt-in to marketing cookies (see
                          section on Cookies below); Our legitimate interests to
                          analyze users’ behaviour in order to further improve
                          our Services.
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                You have the ability to control what information is sent to us
                by apps by changing the apps’
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                settings or changing the permissions on your device.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>
                  Content provided by third parties
                </span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                The Services may show and include content which is hosted on
                external platforms, directly on the page visible during your use
                of the Services, for example an advertisement displayed in an
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                advertisement banner. The privacy policy and cookie policy of
                such third parties is applicable to your visit to such a third
                party website.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We also support sign-in using third party authenticators, such
                as Google or Apple log-in. In such
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                In this case, such third parties may also process your personal
                data in addition to us. Please refer to that third party’s
                privacy policy for their use of your personal data.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Period of retention</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We will retain your Personal Data, including cookies, only for
                as long as is necessary for the purposes set out in this Privacy
                Policy. In some cases, applicable law may require us to retain
                your Personal Data for a certain amount of time. We will retain
                and use your Personal Data to the extent necessary to comply
                with our legal obligations (for example, if we are required to
                retain your data to comply with applicable laws), resolve
                disputes, and enforce our legal agreements and policies.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Such information will be retained until the statue of
                limitations commences. In addition, we retain personal
                information which we are obligated to retain for administrative
                and tax reasons for the duration as required under mandatory
                statutory law.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>
                  Transfer and sharing your Personal Data
                </span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Your information, including Personal Data, may be transferred to
                and maintained on computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                Please note that your information entered into your account is
                stored on the servers of our hosting service provider, Amazon
                Web Services in India. We have provided appropriate safeguards
                as we have entered into a processing agreement based on the
                standard contractual clauses as made available by the European
                Commission, pursuant to the model published on June 4th, 2021.
                Upon your request, we will provide you with a copy of these
                standard contractual clauses.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We will take all steps reasonably necessary to ensure that your
                data is treated securely and no transfer of your Personal Data
                will take place to an organization or a country outside of the
                EER unless there are adequate controls in place including the
                security of your data and other personal information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We may share your personal information with selected partners in
                accordance with the GDPR.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Security</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                We maintain state of the industry security measures to avoid
                unauthorized access to, alteration, disclosure or loss of your
                Personal Data. We process Personal Data in a manner that ensures
                appropriate security of the Personal Data by using commercially
                acceptable industry standards.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>
                  Your rights regarding your Personal Data
                </span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>You have the right:</span>
            </p>
            <ul>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to correct, amend, delete, or limit the use of your Personal
                    Data. We will take all reasonable steps to comply with your
                    requests to exercise your rights in relation to your data.
                    You can update your Personal Data directly within your
                    account settings section. Please contact us if you are
                    unable to make the required changes;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to access and receive a copy of the Personal Data we hold
                    about you;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to rectify any Personal Data held about you that is
                    inaccurate;
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "disc", fontSize: "11pt" }}>
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    to request the deletion of Personal Data held about you.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please
                contact us using the information listed below. Please note that
                we may ask you to verify your identity before responding to such
                requests.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                You have the right to data portability for the information you
                provide to Gully6. You can request
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                to obtain a copy of your Personal Data in a commonly used
                electronic format so that you can
              </span>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>manage and move it.</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Opt-out</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                You can opt-out from receiving our promotional emails at any
                point by clicking on the “Unsubscribe” button located at the
                bottom of all our emails or send an email to
                support@myysports.com.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                If you have any further questions, please do contact us.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span style={{ fontSize: "11pt" }}>Contact Us</span>
              </strong>
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                If you have any questions or grievances about this Privacy
                Policy, please contact us by sending an email to:&nbsp;
              </span>
              <a href="mailto:compliance@myysports.com">
                <u>
                  <span style={{ color: "#1155cc", fontSize: "11pt" }}>
                    compliance@myysports.com
                  </span>
                </u>
              </a>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>DATED: 05.02.2024</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: "11pt" }}>
                This Privacy Policy is subject to change, so please consult this
                page regularly for the latest version. We reserve the right to
                make changes to this Privacy Policy without notifying you.
              </span>
            </p>
          </>
        </Typography>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
