import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { MVPCard } from "./Cards/TopPerformerCard";
import MainHeading from "./Headings/MainHeading";
import GoldBadge from "./../assets/Badge.svg";

const MVP= () => {
  const { mvp } = useSelector((state) => state.match);

  if (!mvp) {
    return null; 
  }

  const {
    playerName,
    teamName,
    batsmanRun,
    playerSR,
    bowlerWicket,
    playerER,
    totalScore,
  } = mvp;

  return (
    <Box sx={{ mt: 2 }}>
    <MainHeading title="MVP" />
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    <MVPCard
      badgeIcon={GoldBadge}
      badgeText="MAN OF THE MATCH" 
      playerName={playerName}
      teamName={teamName}
      Points={totalScore}
      runs={batsmanRun}
      strikeRate={playerSR}
      wickets={bowlerWicket}
      economy={playerER}
    />
    </Box>
    </Box>
  );
};

export default MVP;
