import React, { useEffect } from 'react'
import NoDataComponent from '../NoDataComponent'
import { Box } from '@mui/material'
import TournamentTable from './TournamentTable'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPointsTable } from '../../redux/tournament/tournament.action'
import PointsExplanation from './PointsExplanation'
import { pointData, pointsValue } from '../../constants/constantData'

const PointsTable = ({ tournamentId }) => {
  const dispatch = useDispatch()
  const { leagueTable,ungroupedteams } = useSelector((state) => state.tournament)

  useEffect(() => {
    dispatch(fetchPointsTable(tournamentId))
  }, [dispatch, tournamentId])

  console.log(leagueTable)

  return !leagueTable ? (
    <Box sx={{ mt: 10 }}>
      <NoDataComponent
        label={'POINTS TABLE WILL BE UPDATED AT THE END OF MATCH'}
      />
    </Box>
  ) : (
    <Box>
    <Box sx={{ mx: 1, my: 1, py: 0.5, borderBottom: '1px solid #CBCBCB' }}>
      <PointsExplanation pointData={pointData} />
    <Box sx={{ mx: 1, my: 1 }}>
      <PointsExplanation pointData={pointsValue} />
    </Box>
    </Box>
    <Box sx={{ mt: 1.5 }}>
      <TournamentTable pointsTable={leagueTable} ungroupedTeams={ungroupedteams} />
    </Box>
  </Box>
);
};

export default PointsTable;
