import { Box, Typography } from '@mui/material'
import React from 'react'
import { convertBallsToOver } from '../helpers/calculationLogics'

const RenderPlayerStats = ({ players, type, maxLengthForEllipsis = 100 }) => {
  const ballsPerOvers = 6

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {players?.map((player, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            py: 0.3,
          }}
        >
          {player[`${type}Name`] ? (
            <>
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: `${maxLengthForEllipsis}px`,
                }}
              >
                {player[`${type}Name`]}
              </Typography>
              <Typography variant="body2" sx={{ color: '#D5D5D5' }}>
                {`${player[`${type}${type === 'bowler' ? 'Wkt' : 'Run'}`]} (${
                  type === 'bowler'
                    ? convertBallsToOver(player[`${type}Ball`], ballsPerOvers)
                    : player[`${type}Ball`]
                })`}
              </Typography>
            </>
          ) : null}
        </Box>
      ))}
    </Box>
  )
}

export default RenderPlayerStats
