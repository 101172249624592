import { Box, Typography } from "@mui/material";
import React from "react";

const NoDataComponent = ({ label = "", textTransform = true, imageShown = true, color = "black" }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        mx: 5,
      }}
    >
      {imageShown && <Box component={"img"} src="/images/image-wrap.png" alt="no-scorecard-image" />}
      <Typography
        variant="h6"
        sx={{ fontWeight: 600, fontSize: "14px", textTransform: textTransform ? "uppercase" : "none", color: color }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default NoDataComponent;
