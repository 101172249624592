import { Box } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoDataComponent from '../NoDataComponent';
import MatchItem from '../MatchItem';
import { fetchPlayerMatches } from '../../redux/match/match.action'; 
import { clearGetAllMatches } from '../../redux/match/match.slice';

const PlayerMatches = ({ userObjId }) => {
  const dispatch = useDispatch();
  const getAllMatches = useSelector((state) => state.match.getAllMatches);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 25;
  const prevUserObjId = useRef(userObjId);

  useEffect(() => {
    if (prevUserObjId.current !== userObjId) {
      setCurrentPage(1);
      dispatch(clearGetAllMatches());
      prevUserObjId.current = userObjId;
    }
  }, [userObjId, dispatch]);

  useEffect(() => {
    if (userObjId) {
      dispatch(fetchPlayerMatches(1, pageSize, userObjId)); 
    }
  }, [userObjId, dispatch, pageSize]);

  const handleInfinityScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      setCurrentPage((prev) => {
        const newPage = prev + 1;
        dispatch(fetchPlayerMatches(newPage, pageSize, userObjId));
        return newPage;
      });
    }
  }, [dispatch, pageSize, userObjId]);

  useEffect(() => {
    window.addEventListener('scroll', handleInfinityScroll);
    return () => window.removeEventListener('scroll', handleInfinityScroll);
  }, [handleInfinityScroll]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
      <Box sx={{ py: 2, width: '100%', px: 3 }}>
        {getAllMatches && getAllMatches.length > 0 ? (
          getAllMatches.map((match, index) => (
            <Box key={`${match._id}-${index}`} sx={{ mb: 2 }}>
              <MatchItem match={match} />
            </Box>
          ))
        ) : (
          <NoDataComponent label={'No matches available.'} />
        )}
      </Box>
    </Box>
  );
};

export default PlayerMatches;
