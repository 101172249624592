import React from "react";
import { useSelector } from "react-redux";
import "./css/videoPlayer.css";
import { Box } from "@mui/material";

const VideoPlayer = () => {
  const videoData = useSelector((state) => state.video);

  return (
    <Box sx={{ position: "relative", overflow: "hidden", pb: "56.25%", height: 0 }}>
      <Box
        component={"video"}
        poster="https://media.istockphoto.com/id/502037149/photo/cricket-action.jpg?s=612x612&w=0&k=20&c=zKWIx8c-5BGepNLpmWsQOoaNkZCEAdLr3KT_r8Y8maI="
        controls
        src={videoData?.currentPlayingVideo?.url}
        sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}
      />
    </Box>
  );
};

export default VideoPlayer;
