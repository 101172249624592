// store.js
import { configureStore } from '@reduxjs/toolkit'
import matchReducer from './match/match.slice'
import videoReducer from './video/video.slice'
import tournamentReducer from './tournament/tournament.slice'
const store = configureStore({
  reducer: {
    match: matchReducer,
    video: videoReducer,
    tournament: tournamentReducer,
  },
})

export default store
