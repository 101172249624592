// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
}

.custom-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/videoPlayer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT","sourcesContent":[".video-container {\n  position: relative;\n  overflow: hidden;\n  padding-bottom: 56.25%;\n  height: 0;\n}\n\n.custom-video {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
