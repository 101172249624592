import React, { useEffect, useState } from 'react'; 
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import NoDataComponent from '../NoDataComponent';
import { fetchTournamentMatches } from '../../redux/tournament/tournament.action';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HeadingWithLines from '../../components/Headings/HeadingWithLines';
import TournamentItem from './TournamentItem';
import CalendarView from '../CalenderView'; 

const MatchView = ({ tournamentId }) => {
  const selectedDate = useSelector((state) => state.tournament.date)
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchTournamentMatches(tournamentId,selectedDate));
  }, [dispatch, tournamentId, selectedDate]);

  const {
    loading,
    error,
    tournamentMatches,
  } = useSelector((state) => state.tournament);

  return (
    <Box>
      <HeadingWithLines
        label={'Tournament Schedule'}
        lineWidth={'0.5px'}
        Icon={<CalendarMonthIcon sx={{ fontSize: '14px', mr: 0.5 }} />}
      />
      <CalendarView setCurrentPage={() => {}} tournamentId={tournamentId} /> 
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {tournamentMatches && tournamentMatches.length > 0 ? (
        tournamentMatches.map((match, index) => (
          <TournamentItem
            key={index}
            match={match}
            tournamentId={tournamentId}
            matchId={match.matchJson.matchId}
          />
        ))
      ) : (
        <NoDataComponent label={'No matches available.'} />
      )}
    </Box>
  );
};

export default MatchView;