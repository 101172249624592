import { Box, Typography } from "@mui/material";
import React from "react";

const PointsExplanation = ({ pointData }) => {
  return (
    <Box sx={{ display: "flex", gap: 1, justifyContent: "center", textAlign: "center" }}>
      {pointData.map((point, index) => (
        <Box key={index} sx={{ display: "flex", gap: "2px" }}>
          <Typography sx={{ fontWeight: "700", fontSize: "11px" }}>{point.label}</Typography>
          <Typography sx={{ fontSize: "11px" }}>-</Typography>
          <Typography sx={{ fontSize: "11px" }}>{point.explanation}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PointsExplanation;
