import { createSlice } from '@reduxjs/toolkit'

const tournamentSlice = createSlice({
  name: 'match',
  initialState: {
    data: null,
    loading: false,
    error: null,
    tournamentMatches: [],
    getAllMatches: [],
    tournamentStats: null,
    leagueTable: [],
    ungroupedteams: [],
    groupedteams: [],
    tournamentMatches_currentPage: 0,
    tournamentMatches_pageSize: 25,
    tournament_totalMatches: null,
    tournamentDetails: null,
    currentPage: 1,
    date: null,
  },
  reducers: {
    toggleTournamentLoading: (state) => {
      state.loading = !state.loading
    },
    setTournamentError: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    setTournamentMatches: (state, action) => {
      state.tournamentMatches = action.payload.data.tournamentMatchDetails
      state.tournament_totalMatches =
        action.payload.data.matchPaginationInfo.totalMatches
      state.tournamentDetails = action.payload.data.tournamentDetails
      state.getAllMatches = [
        ...state.getAllMatches,
        ...action.payload.data.tournamentMatchDetails,
      ]
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.data.matchPaginationInfo.currentPage
    },
    setLeagueTable: (state, action) => {
      state.leagueTable = action.payload
    },
    setUngroupedteams: (state, action) => {
      state.ungroupedteams = action.payload
    },
    setGroupedteams: (state, action) => {
      state.groupedteams = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload
    },
    setSelectedDate: (state, action) => {
      state.date = action.payload ?? null
    },
    clearGetAllMatches: (state, action) => {
      state.getAllMatches = []
    },
    fetchTournamentMatches: (state, action) => {
      state.getAllMatches = [...state.getAllMatches]
    },
  },
})

export const {
  toggleTournamentLoading,
  setMatchError,
  setTournamentMatches,
  setCurrentPage,
  setLeagueTable,
  setUngroupedteams,
  setGroupedteams,
  setTournamentError,
  setSelectedDate,
  clearGetAllMatches,
  fetchTournamentMatches,
} = tournamentSlice.actions
export default tournamentSlice.reducer
