import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setCurrentPageAllMatches,
  setPageSizeAllMatches,
} from '../../redux/match/match.slice'
import ActionsMenu from './ActionsMenu'

const MatchTable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const matchState = useSelector((state) => state.match)

  const columns = [
    { field: 'matchId', headerName: 'Match ID', flex: 1 },
    { field: 'matchDateTimeInfo', headerName: 'Created At', flex: 1 },
    {
      field: 'matchPageUrl',
      headerName: 'Match Page',
      flex: 1,
      renderCell: (params) => (
        <a
          href={params.row.matchPageUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to match page
        </a>
      ),
    },
    {
      field: 'actions',
      headerName: 'Action(s)',
      flex: 1,
      renderCell: (params) => (
        <ActionsMenu
          onVisitPage={() => handleVisitPage(params)}
          onViewInfo={() => handleViewInfo(params)}
        />
      ),
    },
    // Add more columns as needed
  ]
  const handleVisitPage = (params) => {
    navigate(`/match/${params.row.matchId}`)
  }

  const handleViewInfo = (params) => {
    navigate(`/match/internal/info/${params.row.matchId}`)
    // Implement logic to show detailed match info
  }
  const handlePaginationModelChange = (model, details) => {
    dispatch(setCurrentPageAllMatches(model.page))
    dispatch(setPageSizeAllMatches(model.pageSize))
  }
  const handleRowSelect = (params) => {
    console.log('isRowSelectable', params)
  }
  return (
    <div
      style={{
        height: 800,
        width: '100%',
        marginTop: '1vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <DataGrid
        rows={matchState?.allMatches || []}
        getRowId={(row) => row._id}
        columns={columns}
        pageSize={matchState?.pageSize || 5}
        rowCount={matchState?.totalMatches || 0}
        pagination
        loading={matchState.loading}
        error={matchState.error}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        // autoHeight={true}
        isRowSelectable={handleRowSelect}
        editMode="row"
        pageSizeOptions={[5, 10, 25, 50, 100]}
        paginationModel={{
          page: matchState?.currentPage,
          pageSize: matchState?.pageSize,
        }}
        sx={{ backgroundColor: 'white' }}
      />
    </div>
  )
}

export default MatchTable
