import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ScoreTable from '../ScoreTable'
const LiveScoreInningTabs = ({ currScore, isLive }) => {
  const [activeTab, setActiveTab] = useState(0)
  const teamKeys = Object.keys(currScore.teamScore)
  const handleButtonClick = (index) => {
    setActiveTab(index)
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        marginBottom={3}
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          paddingX: 1,
        }}
      >
        {teamKeys.map((teamKey, index) => (
          <Button
            key={teamKey}
            onClick={() => handleButtonClick(index)}
            sx={{
              backgroundColor: index === activeTab ? 'black' : '#F3F3F3',
              color: index === activeTab ? 'white' : 'black',
              borderRadius: 2,
              minWidth: '150px',
              marginX: 1,
              '&:hover': {
                backgroundColor: index === activeTab ? 'black' : '#F3F3F3',
              },
            }}
          >
            <Typography
              sx={{
                color: index === activeTab ? '#ffffff' : '#000000',
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '@media screen and (max-width: 600px)': {
                  maxWidth: '60%',
                },
              }}
            >
              {teamKey}
            </Typography>
          </Button>
        ))}
      </Box>
      <Box pb={2}>
        <ScoreTable
          inning={null}
          isLive={isLive}
          currOver={currScore?.currOverDetail}
          teamScore={currScore.teamScore[teamKeys[activeTab]]}
        />
      </Box>
    </Box>
  )
}

export default LiveScoreInningTabs
