import { Box } from "@mui/system";
import React from "react";

const MainWidthWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        fontFamily: "Arial, sans-serif",
        mx: "auto",
        width: { md: "55%", mg: "100%" },
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      {children}
    </Box>
  );
};

export default MainWidthWrapper;
