import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { fetchMatchData } from '../../redux/match/match.action'
import Result from '../../components/Result'
import ScoreCard from '../../components/scoreCard'
import VideoList from '../../components/videoList'
import VideoPlayer from '../../components/videoPlayer'
import MainHeading from '../../components/Headings/MainHeading'
import MainWidthWrapper from '../../components/MainWidthWrapper'
import socketIOClient from 'socket.io-client'
import {
  setCurrScoreOnRealTimeUpdate,
  setIsLive,
} from '../../redux/match/match.slice'
import LiveWinProbabilityPredictor from '../../components/WinProbability'
import Table from '../../components/MatchInfo'
import CustomTabs from '../../components/Tabs'
import OverTracker from '../../components/Overtracker'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LiveScoringButton from '../../components/LiveButton'
import moment from 'moment'

const MatchContainer = () => {
  const dispatch = useDispatch();
  const { matchId } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const matchData  = useSelector((state) => state.match);
  const videoData = useSelector((state) => state.video);

  const fullMatchVideo = videoData?.summaryAndFullMatchVideosList?.find(
    (video) => video.videoCategory === 'FULL_MATCH_VIDEO'
  )

  useEffect(() => {
    dispatch(fetchMatchData(matchId, true))
  }, [dispatch, matchId])

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_API_BASE_URL, {
      path: '/api/ws/',
      // withCredentials: true,
    })
    // Listen for updates specific to the matchId
    socket.on(`match:${matchId}`, (updatedMatch) => {
      if (!updatedMatch?.isLive) {
        setTimeout(() => {
          dispatch(fetchMatchData(matchId, true));
        }, 3000);
      } else {
        dispatch(setIsLive(true));
      }
      dispatch(setCurrScoreOnRealTimeUpdate(updatedMatch));

      console.log('match: ${matchId}', updatedMatch);
    });

    return () => {
      socket.disconnect()
    }
  }, [matchId, matchData?.isLive, dispatch])

  const handleTabChange = (newValue) => {
    setActiveTab(newValue)
  }

  const {info} = matchData.data?.[0] || {};
  const { date, numOfPlayerInEachTeam, limitedOver, tossWinner, tossDecision } =
    info?.[0] || {};

  const matchDate = moment(date).utc().format('dddd DD MMMM YYYY');
  const matchTime = moment(date).utc().format('hh:mm A');
  const format = `Gully ${numOfPlayerInEachTeam}X${limitedOver}`;
  let tossDecisionText = '';

if (tossDecision === 'Batting') {
  tossDecisionText = 'elected to bat first';
} else {
  tossDecisionText = 'elected to field first';
}
const Toss = `${tossWinner?.toUpperCase() || 'UNKNOWN'} , ${tossDecisionText}`;

const userProfile = matchData?.userProfile || {};
const Name = userProfile?.name?.firstName || 'Unknown';
const ProfilePic = userProfile?.profilePic || '';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const capitalizedName = capitalizeFirstLetter(Name);
 
   const columns = [
    {
      label: 'Match Format',
      imgUrl: '',
      alignment: 'right',
      value: format,
    },
    {
      label: 'Date',
      imgUrl: '',
      alignment: 'right',
      value: matchDate, 
    },
    {
      label: 'Time',
      imgUrl: '',
      alignment: 'right',
      value: matchTime, 
    },
    {
      label: 'Toss',
      imgUrl: '',
      alignment: 'right',
      value: Toss, 
    },
    {
      label: 'Match Creator',
      alignment: 'right',
      value: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={ProfilePic}
            alt="Profile"
            style={{
              borderRadius: '50%',
              height: '2.5em',
              width: '2.5em',
              marginRight: '0.5em'
            }}
          />
         {capitalizedName}
        </div>
      ), 
    },
  ];

  const tabs = [
    { value: 0, label: 'SCORECARD', isVisible: true },
    { value: 1, label: 'MATCHINFO', isVisible: true },
  ]
  if (videoData?.individualHiglightClipsList?.length !== 0) {
    tabs.push({ value: 3, label: 'videos', isVisible: true })
    tabs.find((tab) => tab.label === 'MATCHINFO').isVisible = false
  }
  return (
    <MainWidthWrapper>
      <Box className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Gully Six</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </Box>
      {matchData.loading && <Box>Loading...</Box>}
      {matchData.data && videoData?.individualHiglightClipsList?.length !== 0 ? (
        <>
          {fullMatchVideo ? (
            <VideoPlayer />
          ) : (
            <Box sx={{ py: '20px', bgcolor: 'black' }}>
              <Typography variant="h6" color="white">MATCH PAGE</Typography>
            </Box>
          )}
          <Result matchData={matchData.data} />
          <Box sx={{ mt: '0px' }}>
            <CustomTabs
              value={activeTab}
              setValue={handleTabChange}
              tabs={tabs}
            />
            <Box sx={{ mt: '0' }}>
              {activeTab === 0 && (
                <Box sx={{ mt: '0px' }}>
                  <ScoreCard
                    matchData={matchData.data}
                    isLive={matchData.isLive}
                    currScore={matchData.currScore}
                  />
                </Box>
              )}
              {activeTab === 3 && (
                <Box sx={{ mt: '0px' }}>
                  <VideoList />
                </Box>
              )}
              {activeTab === 1 && (
                <Box sx={{ mt: '0px' }}>
                  <Table columns={columns} />
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        matchData.data && videoData?.individualHiglightClipsList?.length === 0 && (
          <Box sx={{ pb: 4 }}>
            {fullMatchVideo ? (
              <VideoPlayer />
            ) : (
              <Box sx={{ bgcolor: 'black', p: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                    }}
                  >
                    {/* <ArrowBackIcon sx={{ fontSize: '1rem' }} />
                    <Typography variant="body2" sx={{ ml: 0.5, color: 'white', fontSize: '0.8rem' }}>BACK</Typography> */}
                  </Box>
                  <Typography variant="subtitle1" sx={{ textAlign: 'center', width: '100%', color: 'white', fontSize: '1.1rem' }}>MATCH PAGE</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LiveScoringButton />
                </Box>
              </Box>
            )}
            <Result matchData={matchData.data} />
            <Box sx={{ mt: '0px' }}>
              {matchData.isLive && (
                <>
                  <OverTracker />
                  {/* <LiveWinProbabilityPredictor /> */}
                </>
              )}

              <CustomTabs
                value={activeTab}
                setValue={handleTabChange}
                tabs={tabs}
              />
              <Box sx={{ mt: '0' }}>
                {activeTab === 0 && (
                  <Box sx={{ mt: '0px' }}>
                    <ScoreCard
                      matchData={matchData.data}
                      isLive={matchData.isLive}
                      currScore={matchData.currScore}
                    />
                  </Box>
                )}

                {activeTab === 1 && (
                  <Box sx={{ mt: '0px' }}>
                    <Table columns={columns} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )
      )}
    </MainWidthWrapper>
  );
};

export default MatchContainer;