import { Box, Card, Container, Typography } from "@mui/material";
import React from "react";

const StatsCard = ({ icon, title, value }) => {
  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "0px 6px 16px 0px #14142B1C",
        border: "2px solid #F0F2F5",
        borderRadius: "16px",
        py: { xs: 2.5, sm: 3 },
        position: "relative",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: { xs: 0, sm: 0.5 }, 
          px: { xs: 0, sm: 0 }, 
          flexDirection: "row",
          width: "100%", 
        }}
      >
        <Box
          component="img"
          src={icon}
          alt="Icon"
          sx={{
            width: { xs: 40, sm: 60 },
            height: { xs: 40, sm: 60 },
            ml: { xs: 1, sm: 1 },
          }}
        />
       <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      <Typography
      variant="body1"
      sx={{
        fontSize: { xs: "16px", sm: "24px" },
        fontWeight: 600,
        lineHeight: { xs: "20px", sm: "28px" },
        ml: { xs: 3, sm: 3 },
        textAlign: "left", 
        }}
        >
        {value}
      </Typography>
      <Typography
      variant="body2"
      sx={{
        fontSize: { xs: "12px", sm: "18px" },
        fontWeight: 400,
        color: "#9098A3",
        ml: { xs: 2, sm: 2 },
        textAlign: "left", 
      }}
    >
      {title}
    </Typography>
    </Box>
      </Container>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: "2px solid rgba(0, 0, 0, 0.1)", 
          borderRadius:  '16px',
          pointerEvents: "none", 
        }}
      />
    </Card>
  );
};

export default StatsCard;
