import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../../components/Header";

const DeleteAccount = () => {
  return (
    <>
      <Header />

      <Box
        sx={{
          fontFamily: "Arial, sans-serif",
          backgroundColor: "#f4f4f4",
          margin: 0,
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          className="container"
          sx={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" sx={{ color: "#333", textAlign: "center" }}>
            Delete Your Account
          </Typography>
          <Typography paragraph sx={{ color: "#666", marginBottom: "20px" }}>
            If you want to delete your Gully6 account, please write to{" "}
            <a
              href="mailto:support@myysports.com"
              style={{ textDecoration: "none", color: "#007bff" }}
            >
              support@myysports.com
            </a>{" "}
            and raise an account deletion request.
          </Typography>
          <Typography sx={{ color: "#666" }}>
            We will respond to your request within 45 days.
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default DeleteAccount;
