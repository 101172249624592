import { Box } from '@mui/material'
import React from 'react'
import NoDataComponent from './NoDataComponent'
import ScoreInningTabs from './ScoreInningTabs'
import LiveScoreInningTabs from './liveScoreCard/LiveScoreInningTabs'

const ScoreCard = ({ matchData, isLive, currScore }) => {
  const { teams, innings } = matchData?.[0]?.info?.[0] || {}
  return innings && !isLive ? (
    <ScoreInningTabs inningsData={innings} teamData={teams} />
  ) : (
    <Box>
      {!isLive && (
        <NoDataComponent
          textTransform={false}
          imageShown={false}
          color={'#FF6347'}
          label={
            "Match is scheduled! Check back later for match updates. Don't forget to copy the Match ID using the ℹ️ button for scoring and quick access later!"
          }
        />
      )}
      {isLive && (
        <LiveScoreInningTabs
          currScore={currScore}
          inningsData={innings}
          isLive={isLive}
        />
      )}
    </Box>
  )
}

export default ScoreCard
