import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ProfilePic from '../Team-Profile/ProfilePic';
import UserProfile from '../Team-Profile/UserProfile';
import PlayerMatches from '../Player-Profile/Matches';
import PlayerStats from '../Player-Profile/Playerstats';
import PlayerProfileTabs from '../Player-Profile/PlayerProfiletabs';
import MainWidthWrapper from '../MainWidthWrapper';
import MainHeading from '../Headings/MainHeading';
import { fetchPlayerData } from '../../redux/match/match.action';

const PlayerProfilepage = () => {
    const { userName } = useParams();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const playerData = useSelector((state) => state.match.playerData);
    const [playerStats, setPlayerStats] = useState(null);

    useEffect(() => {
        dispatch(fetchPlayerData(userName));
    }, [dispatch, userName]);

    useEffect(() => {
        if (playerData) {
            setPlayerStats({
                Matches: playerData.stats.totalMatches,
                Runs: playerData.stats.totalRuns,
                Wickets: playerData.stats.totalWickets,
                Wins: playerData.stats.totalWins,
            });
        }
    }, [playerData]);

    return (
        <MainWidthWrapper>
            <Box sx={{ py: 2.5 }}>
                <MainHeading label={'PLAYER PROFILE'} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0, mx: 0, mb: 0, borderBottom: '2px solid #33333326', width: '100%' }}>
                <Box>
                    <ProfilePic profileType="player" avatarUrl={playerData?.profilePicture} />
                </Box>
                <Box>
                    <UserProfile name={playerData?.name?.firstName} username={`@${userName}`} isHeader={true} />
                </Box>
            </Box>
            <Box>
                <PlayerProfileTabs value={value} setValue={setValue} />
            </Box>
            <Box sx={{ px: 0, width: "100%" }}>
                {value === 0 && <PlayerMatches userObjId={playerData?.userObjId} />}
                {value === 1 && <PlayerStats player={playerStats} />}
            </Box>
        </MainWidthWrapper>
    );
};

export default PlayerProfilepage;
