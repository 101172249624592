// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-list-component-container{
    font-family: Arial, sans-serif;
    padding: 10px;
    height: 400px;
    /* background-color: #000000; */
}
.video-list-container{
    height: 100%;
    overflow-y: auto; 
}`, "",{"version":3,"sources":["webpack://./src/components/css/videoList.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,aAAa;IACb,aAAa;IACb,+BAA+B;AACnC;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".video-list-component-container{\n    font-family: Arial, sans-serif;\n    padding: 10px;\n    height: 400px;\n    /* background-color: #000000; */\n}\n.video-list-container{\n    height: 100%;\n    overflow-y: auto; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
