export function calculateWideNoBallRuns(overDetails) {
  // Check if overDetails is provided and has length
  let totalWideBall = 0
  let totalNoBall = 0
  if (!overDetails || overDetails.length === 0) {
    console.error('Invalid or empty over details array provided.')
    return { totalWideBall, totalNoBall }
  }

  try {
    overDetails.forEach((over) => {
      if (over.balls && Array.isArray(over.balls)) {
        over.balls.forEach((ball) => {
          if (
            ball.wide_noBall &&
            Array.isArray(ball.wide_noBall) &&
            ball.wide_noBall.length > 0
          ) {
            ball.wide_noBall.forEach((wideNoBall) => {
              if (wideNoBall.type.toLowerCase() === 'wide ball') {
                totalWideBall += 1
              } else if (wideNoBall.type.toLowerCase() === 'no ball') {
                totalNoBall += 1
              }
            })
          }
        })
      }
    })
  } catch (error) {
    console.error('An error occurred while calculating runs:', error)
    return { totalWideBall, totalNoBall }
  }

  // Return the total runs from wide balls and no balls
  return { totalWideBall, totalNoBall }
}

export function calculateDotBalls(overDetails) {
  // Check if overDetails is provided and has length
  if (!overDetails || overDetails.length === 0) {
    console.error('Invalid or empty over details array provided.')
    return []
  }

  const bowlerDotBalls = {}

  try {
    // Iterate over each ball in the over
    overDetails.forEach((over) => {
      if (over.balls && Array.isArray(over.balls)) {
        over.balls.forEach((ball) => {
          const bowlerName = ball.bowler

          // Check if the run on the ball is 0 and wide_noBall array is empty (dot ball)
          const isDotBall =
            ball.ballrun === '0' &&
            (!ball.wide_noBall || ball.wide_noBall.length === 0)

          // Update dot ball count for the bowler
          if (bowlerName && isDotBall) {
            if (!bowlerDotBalls[bowlerName]) {
              bowlerDotBalls[bowlerName] = 1
            } else {
              bowlerDotBalls[bowlerName]++
            }
          }
        })
      }
    })
  } catch (error) {
    console.error('An error occurred while calculating dot balls:', error)
    return []
  }

  // Convert the dot ball information to an array of objects with bowlerName and dotBalls
  // const resultArray = Object.entries(bowlerDotBalls).map(([bowlerName, dotBalls]) => ({
  //     bowlerName,
  //     dotBalls,
  // }));

  return bowlerDotBalls
}

export function fetchWicketFall(data) {
  let overDetailsCount = 0
  let ballCount = 0
  const resultArray = []

  data.forEach((over) => {
    overDetailsCount++

    over.balls.forEach((ball) => {
      ballCount++
      const batsMan =
        ball?.outPlayerName !== 'null' ? ball?.outPlayerName : ball.batsMan
      for (const wideNoBall of ball.wide_noBall) {
        if (wideNoBall.run.toLowerCase().startsWith('w')) {
          const ballInfo = {
            batsman:
              wideNoBall.batsMan === 'null' || !wideNoBall.batsMan
                ? ''
                : wideNoBall?.outPlayerName || wideNoBall.batsMan,
            currentRun: wideNoBall.currentScore,
            overDetailsCount: overDetailsCount,
            ballCount: ballCount - 1,
          }
          resultArray.push(ballInfo)
        }
      }
      if (ball.ballrun.toLowerCase().startsWith('w')) {
        const currentRun = ball.currentScore

        const ballInfo = {
          batsman: batsMan || '',
          currentRun: currentRun,
          overDetailsCount: overDetailsCount,
          ballCount: ballCount,
        }

        resultArray.push(ballInfo)
      }
    })
  })

  return resultArray
}

export const convertBallsToOver = (bowls, ballsPerOver) => {
  return `${Math.floor(bowls / ballsPerOver)}.${bowls % ballsPerOver}`
}
