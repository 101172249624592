import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckIcon from "@mui/icons-material/Check";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "18px",
  border: "none",
};

export default function InfoModal({ open, setOpen, matchId = "NA" }) {
  const handleClose = () => setOpen(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(matchId);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="body2" fontSize="16px" fontWeight={700}>
            MATCH ID
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Box
              sx={{ bgcolor: "#0A0A0A1A", p: 2, borderRadius: "12px", my: 2, display: "flex", alignItems: "center" }}
              onClick={handleCopyClick}
            >
              <Typography variant="body2" sx={{ fontSize: "24px", fontWeight: 700, pl: 1 }}>
                {matchId}
              </Typography>
              <IconButton color="primary">
                <FileCopyIcon fontSize="medium" sx={{ fill: "black" }} />
              </IconButton>
            </Box>
          </Box>
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: "#FFCB05",
              borderRadius: "8px",
              color: "black",
              "&:hover": {
                bgcolor: "#FFCB05",
              },
            }}
            onClick={handleClose}
          >
            OK <CheckIcon sx={{ ml: 0.3 }} />
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Match ID copied to clipboard!"
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CheckIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}
