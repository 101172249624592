import React from 'react'
import { Box, Typography } from '@mui/material'
import MainHeading from './Headings/MainHeading'
import RenderPlayerStats from './RenderPlayerStats'
import UnderLine from './UnderLine'
import { useSelector } from 'react-redux'
import MVP from './MVP'

const TeamInfo = ({ team, headingColor }) => (
  <Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography
        sx={{
          color: headingColor,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '@media screen and (max-width: 600px)': {
            maxWidth: '60%',
          },
        }}
      >
        {team?.battingTeamName}
      </Typography>
      <Typography variant="body2" sx={{ color: '#D5D5D5' }}>
        Inning-{team?.inningId}
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <RenderPlayerStats players={team?.topBatters} type={'batsman'} />
      <RenderPlayerStats players={team?.topBowlers} type={'bowler'} />
    </Box>
  </Box>
)

const TopPerformer = ({ topPerformers }) => {
  const { data } = useSelector((state) => state.match)
  const [team1, team2] = topPerformers
  const teamName1 = data[0].info[0].teams[0].teamName
  const teamName2 = data[0].info[0].teams[1].teamName
  return (
    <Box px={3}>
      <Box sx={{ py: '10px' }}>
        <MainHeading
          color={'white'}
          bgColor={'black'}
          label={'Top Performers'}
        />
      </Box>

      <Box sx={{ py: 1 }}>
        <TeamInfo
          team={team1}
          headingColor={
            teamName1 === team1?.battingTeamName ? '#ff6161' : '#9a7bf8'
          }
        />
      </Box>
      <Box sx={{ py: 1 }}>
        <UnderLine />
      </Box>
      <Box sx={{ py: 1 }}>
        <TeamInfo
          team={team2}
          headingColor={
            teamName2 === team2?.battingTeamName ? '#9a7bf8' : '#ff6161'
          }
        />
      </Box>
      <Box sx={{ py: 1 }}>
        <MVP />
      </Box>
    </Box>
  )
}

export default TopPerformer
