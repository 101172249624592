import { createSlice } from '@reduxjs/toolkit'

const matchSlice = createSlice({
  name: 'match',
  initialState: {
    data: null,
    loading: true,
    error: null,
    allMatches: [],
    getAllMatches: [],
    userProfile: {},
    topPerformers: null,
    mvp: null,
    currentPage: 1,
    pageSize: 25,
    totalMatches: null,
    date: null,
    totalPages: 1,
    isLive: false,
    currScore: null,
    target: null,
    teamData: null, 
    teamLoading: false,
    playerData: null,
    playerLoading: false,
  },
  reducers: {
    setMatchData: (state, action) => {
      state.data = action.payload.matchData
      state.userProfile = action.payload.userProfile
      state.topPerformers = action.payload.topPerformers
      state.mvp = action.payload.mvp
      state.loading = false
      state.error = null
      state.isLive = action.payload.isMatchLive
      state.currScore = action.payload.currScore
    },
    setMatchLoading: (state) => {
      state.loading = true
    },
    setMatchError: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    setTotalMatches: (state, action) => {
      state.allMatches = action.payload.matchesInfo
      state.getAllMatches = [
        ...state.getAllMatches,
        ...action.payload.matchesInfo,
      ]
      state.totalMatches = action.payload.totalMatches
      state.loading = false
      state.totalPages = action.payload.totalPages || 1
    },
    setCurrentPageAllMatches: (state, action) => {
      state.currentPage = action.payload
    },
    setPageSizeAllMatches: (state, action) => {
      state.pageSize = action.payload
    },
    setSelectedDate: (state, action) => {
      state.date = action.payload ?? null
    },
    clearGetAllMatches: (state, action) => {
      state.getAllMatches = []
    },
    setCurrScoreOnRealTimeUpdate: (state, action) => {
      state.currScore = action.payload.currScore
    },
    setIsLive: (state, action) => {
      state.isLive = action.payload
    },
    setTeamData: (state, action) => {
      state.teamData = action.payload;
      state.teamLoading = false;
    },
    setTeamLoading: (state) => {
      state.teamLoading = true;
    },
    setTeamError: (state, action) => {
      state.error = action.payload;
      state.teamLoading = false;
    },
    setPlayerData: (state,action) => {
      state.playerData = action.payload;
      state.playerLoading = false;
    },
    setPlayerLoading: (state) =>{
      state.playerLoading = false;
    },
    setPlayerError: (state, action) => {
      state.error = action.payload;
      state.playerLoading = false;
    },
  },
})

export const {
  setMatchData,
  setMatchLoading,
  setMatchError,
  setTotalMatches,
  setCurrentPageAllMatches,
  setPageSizeAllMatches,
  setSelectedDate,
  clearGetAllMatches,
  setCurrScoreOnRealTimeUpdate,
  setIsLive,
  setTeamData, 
  setTeamLoading, 
  setTeamError, 
  setPlayerData,
  setPlayerLoading,
  setPlayerError
} = matchSlice.actions
export default matchSlice.reducer
