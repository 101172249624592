import { Box, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AvatarImg from '../../assets/Avatar-Image.svg'
import ProfileAvatar from './ProfileAvatar'
import { useSelector } from 'react-redux'

const ProfileInfo = () => {
  const { loading, tournamentDetails, error } = useSelector(
    (state) => state.tournament
  )
  return (
    <Box sx={{ mb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mx: 2 }}>
        <ProfileAvatar avatar={AvatarImg} />
        <Box>
          <Typography
            variant="body2"
            sx={{ fontSize: '25px', fontWeight: 700 }}
          >
            {tournamentDetails?.name || 'remove this'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const FollowInfo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 1,
        mx: 2,
        ml: 20,
        mr: 'auto',
      }}
    >
      <Box sx={{ textAlign: 'center', mr: 2 }}>
        <Typography variant="body2" sx={{ fontSize: '14px', color: '#8e8e8e' }}>
          <h4>10</h4>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '14px', color: '#8e8e8e' }}>
          <h4>Following</h4>
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', ml: 2, mr: 2 }}>
        <Typography variant="body2" sx={{ fontSize: '14px', color: '#8e8e8e' }}>
          <h4>20</h4>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '14px', color: '#8e8e8e' }}>
          <h4>Followers</h4>
        </Typography>
      </Box>
      <Box sx={{ ml: 'auto', mr: 15 }}>
        <Button variant="contained" sx={{ bgcolor: '#3897f0', color: 'white' }}>
          Follow
        </Button>
      </Box>
    </Box>
  )
}

const ProfilePage = () => {
  return (
    <React.Fragment>
      <ProfileInfo />
      {/* <FollowInfo /> */}
    </React.Fragment>
  )
}

export default ProfilePage
