import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function ProfileTabs({ value, setValue }) {
  const tabs = [
    { value: 0, label: "MATCHES" },
    { value: 1, label: "POINTS TABLE" },
    { value: 2, label: "STATS" },
  ];

  const changeTab = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", px: 3, width: "100%" }}>
      {tabs.map((tab) => (
        <Button
          key={tab.value}
          sx={{
            borderBottom: `3px solid ${value === tab.value ? "black" : "#CBCBCB"}`,
            color: "black",
            fontWeight: value === tab.value ? 700 : 500,
            minWidth: "114px",
            borderRadius: 0,
            "&:hover": {
              borderBottom: value === tab.value && "2px solid black",
            },
          }}
          color="primary"
          onClick={() => changeTab(tab.value)}
        >
          {tab.label}
        </Button>
      ))}
    </Box>
  );
}
