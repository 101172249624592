import React, { useEffect } from "react";

import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchMatchData } from "../../../redux/match/match.action";

export default function MatchJsonViewPage() {
  // const [json, setJson] = React.useState(data)
  const dispatch = useDispatch();
  const { matchId } = useParams();
  const matchState = useSelector((state) => state.match);
  useEffect(() => {
    dispatch(fetchMatchData(matchId, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <div className="App" style={{ backgroundColor: "white" }}>
      <ReactJson src={matchState?.data} defaultValue={{}} />
    </div>
  );
}
