import React from 'react';
import { Container, Typography, Box } from "@mui/material";
import Header from '../../components/Header';

const AboutUsPage = () => {
  return (
    <>
    <Header/>

    <Box
    sx={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f4f4f4",
        margin: 0,
        padding: "10px",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
    }}
  >
      <h1>Gully6: Bano Apni Gully Ka Cricket Champion 🏏✨</h1>
      <p>
        Gully or backyard cricket has emerged as the grassroots heartbeat of the cricketing culture in India, countering the challenges posed by the traditional formats. In a nation where cricket holds an unparalleled fascination, participation had been hindered by multiple barriers such as limited access to stadiums, the cost associated with professional gear, the time-consuming nature of long-format games, concerns about injuries, and the absence of companions for white-ball professional cricket formats like ODI, T50, T40, and T20. Unlike most sports that have adapted to faster, modern formats, cricket has retained its leisurely pace, rooted in its historical origins during the British Raj. However, 'Gully Cricket' has emerged as the people's game, transcending barriers and embodying the true spirit of cricket. It thrives as a mass format in South Asia, offering enthusiasts a more accessible, spontaneous, and community-driven version of the sport, fostering a love for the game at its most organic level. Best part of Gully Cricket is Play Anywhere, Anytime. No fancy cricket ground needed! Gully6 turns streets, residential societies, and cricket boxes into your cricket pitch. You can experience the thrill of gully cricket wherever you go. At Gully6, we live and breathe this exhilarating spirit of gully cricket. We're on a mission to turn every street into a cricketing battlefield, resonating with the cheers and camaraderie of cricket enthusiasts. Whether you're a seasoned pro or just starting your cricket journey,
      </p>
      <h2>Why Gully6?</h2>
      <ul>
        <li>🌐 ⚡ Fast-Paced Matches: Immerse yourself in the adrenaline-fueled world of short-format cricket. Gully6 is crafted for quick, action-packed matches that embody the true spirit of gully cricket.</li>
        <li>✨ Create Your Own Teams: Form your dream team with friends, neighbors, or anyone with a passion for gully cricket. Choose your players, assign roles, and strategize for street dominance.</li>
        <li>🏆 Organize Tournaments: Become the mastermind behind epic gully cricket tournaments. Set up knockout stages, define rules, and invite teams for a showdown. Claim the title of gully cricket champion in your locality!</li>
        <li>📊 Scoring, Track Stats and Leaderboards: Dive into the world of gully and box cricket with specialized scoring. Keep tabs on your team's performance, monitor individual player statistics, and climb the leaderboards. Compete with fellow gully cricket enthusiasts and showcase your skills.</li>
        <li>🌟 MVP Ratings: Break free from the match format constraints with MVP ratings that are independent. Recognize standout performances and celebrate the true stars of gully cricket.</li>
        <li>🌟 Automated Video Highlights: Relive your best moments with automated video highlights of every match. From incredible sixes to stunning catches, capture the magic and share it with the Gully6 community.</li>
        <li>🎉 Celebrate Victories: Capture and share your triumphs with in-app statistics and video sharing. Relive your unforgettable cricket memories and celebrate victories in style with the Gully6 community.</li>
        <li>🤝 Social Interaction Features: Connect and share your gully cricket experience with a by interacting with the vibrant gully and box cricket community</li>
        <li>📲 User-Friendly Interface: Whether you're a seasoned gully cricket pro or a newbie, Gully6 ensures a seamless experience with its user-friendly interface.</li>
      </ul>
      <p>Download Gully6 now and let the gully cricket fever take over. Form teams, organize tournaments, and play cricket the way it was meant to be - Apni Gully Mein! 🌟🤝</p>
    </Box>
    </>
  );
};

export default AboutUsPage;