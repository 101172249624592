import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import NoDataComponent from "../NoDataComponent";
import StatsCard from "../Cards/StatsCard";
import { TopPerformerCard, MVPCard } from "../Cards/TopPerformerCard";
import MainHeading from "../Headings/MainHeading";
import axios from "axios";
import ThunderIcon from "../../assets/Thunder.svg";
import GraphIcon from "../../assets/Graph.svg";
import GoldBadge from "../../assets/Badge.svg";

const Stats = ({ tournamentId }) => {
  const [tournamentStats, setTournamentStats] = useState(null);

  useEffect(() => {
    const fetchTournamentStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournament/stats/${tournamentId}`);
        setTournamentStats(response.data.data);
      } catch (error) {
        console.error("Error fetching tournament stats:", error);
      }
    };

    fetchTournamentStats();
  }, [tournamentId]);

  return (
    <Box sx={{ mt: 1.5, maxWidth: "100%", margin: "0 auto", padding: "0px" }}> 
      {tournamentStats ? (
        <>
          <Box sx={{ mx: 0, my: 0, py: 0, borderBottom: "1px solid #CBCBCB" }}>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 0, my: 1 }}>
              <StatsCard icon={ThunderIcon} title="Total Runs" value={tournamentStats.totalRunsInTournament} />
              <StatsCard icon={GraphIcon} title="Total Wickets" value={tournamentStats.totalWicketsInTournament} />
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <MainHeading title="PLAYER OF THE TOURNAMENT" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {tournamentStats.tournamentPlayerScore && tournamentStats.tournamentPlayerScore.length > 0 && (
                <MVPCard
                  badgeIcon={GoldBadge}
                  badgeText="PLAYER OF THE TOURNAMENT"
                  playerName={tournamentStats.tournamentPlayerScore[0].playerName}
                  teamName={tournamentStats.tournamentPlayerScore[0].teamName}
                  Points={tournamentStats.tournamentPlayerScore[0].totalScore}
                  runs={tournamentStats.tournamentPlayerScore[0].batsmanRun}
                  strikeRate={tournamentStats.tournamentPlayerScore[0].playerSR}
                  wickets={tournamentStats.tournamentPlayerScore[0].bowlerWicket}
                  economy={tournamentStats.tournamentPlayerScore[0].playerER}
                />
              )}
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <MainHeading title="BEST BATSMAN" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {tournamentStats.bestBatsmans.map((player) => (
                <TopPerformerCard
                  key={player.playerId}
                  badgeIcon={GoldBadge}
                  badgeText="BEST BATTER"
                  playerName={player.playerName}
                  runs={player.totalRuns}
                  wickets={player.totalWickets}
                  strikeRate={player.strikeRate}
                  economyRate={player.economyRate}
                  fours={player.fours}
                  sixes={player.sixes}
                  matchesPlayed={player.matchesPlayed}
                  matchesWon={player.matchesWon}
                  teamName={player.teamName}
                />
              ))}
            </Box>
          </Box>

          <Box sx={{ mt: 2 }}>
            <MainHeading title="BEST BOWLER" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {tournamentStats.bestBowlers.map((player) => (
                <TopPerformerCard
                  key={player.playerId}
                  badgeIcon={GoldBadge}
                  badgeText="BEST BOWLER"
                  playerName={player.playerName}
                  runs={player.totalRuns}
                  wickets={player.totalWickets}
                  strikeRate={player.strikeRate}
                  economyRate={player.economyRate}
                  fours={player.fours}
                  sixes={player.sixes}
                  matchesPlayed={player.matchesPlayed}
                  matchesWon={player.matchesWon}
                  teamName={player.teamName}
                />
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <NoDataComponent label="STATS WILL BE UPDATED AT THE END OF MATCH" />
      )}
    </Box>
  );
};

export default Stats;
