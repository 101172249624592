import React, { useState, useEffect } from 'react'
import MainWidthWrapper from '../../components/MainWidthWrapper'
import ProfileInfo from '../../components/Tournament-Profile/ProfileInfo'
import { Box } from "@mui/system";
import ProfileTabs from '../../components/Tournament-Profile/ProfileTabs'
import MainHeading from '../../components/Headings/MainHeading'
import MatchView from '../../components/Tournament-Profile/MatchView'
import PointsTable from '../../components/Tournament-Profile/PointsTable'
import Stats from '../../components/Tournament-Profile/Stats'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const ProfileContainer = () => {  
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  const { tournamentId } = useParams()
  
  return (
    <MainWidthWrapper>
      <Box sx={{ py: 2 }}>
        <MainHeading label={'TOURNAMENT PROFILE'} />
      </Box>
      <Box sx={{ mb: 4, borderBottom: '1px solid #33333326' }}>
        <ProfileInfo />
      </Box>

      <Box >
        <ProfileTabs value={value} setValue={setValue} />
      </Box>

      <Box sx={{ px: 3 , width: "100%" }}>
        {value === 0 && <MatchView tournamentId={tournamentId}/>}
        {value === 1 && <PointsTable tournamentId={tournamentId} />}
        {value === 2 && <Stats tournamentId={tournamentId} />}
      </Box>
    </MainWidthWrapper>
  )
}

export default ProfileContainer;