import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const OverTracker = () => {
  const { currScore } = useSelector((state) => state.match)
  const [overData, setOverData] = useState([])
  const [currentOver, setCurrentOver] = useState('0.0')
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const generateOverData = () => {
      const runsToColor = {
        0: '#FFFFFF', // White
        1: '#008000', // Green
        2: '#FFA500', // Orange
        3: '#FFC0CB', // Pink
        4: '#0000FF', // Blue
        6: '#FFFF00', // Yellow
        W: '#FF0000', // Red for Wicket
      }

      if (currScore?.currOverDetail?.balls) {
        const response = currScore.currOverDetail.balls

        const updatedResponse = response
          .filter((ballData) => ballData.runs ?? ballData.run)
          .map((ballData) => {
            let runs = ballData.run
            if (ballData.isWide) {
              runs += 'WD'
            }
            if (ballData.isNoBall) {
              runs += 'NB'
            }
            if (ballData.isBye === 1) {
              runs += 'B'
            }
            return {
              ...ballData,
              color: ballData?.color || runsToColor[runs] || '#FFFFFF',
              runs: runs,
            }
          })

        setOverData(updatedResponse)
      }
    }
    if (currScore?.currOverDetail?.over) {
      setCurrentOver(currScore.currOverDetail.over)
    }
    generateOverData()
  }, [currScore])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    if (currScore?.currOverDetail?.over) {
      setCurrentOver(currScore.currOverDetail.over)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const boxStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isMobile ? '0px' : '2px',
  }

  const innerBoxStyle = {
    width: isMobile ? '130%' : '145%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: isMobile ? '0px' : '2px',
  }

  const circleBoxStyle = {
    width: isMobile ? '40px' : '50px',
    height: isMobile ? '40px' : '50px',
    borderRadius: '50%',
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2px',
    marginLeft: '5px',
  }

  return (
    <Box
      sx={{
        marginTop: '0px',
        mb: 0,
        p: 1,
        bgcolor: 'black',
        border: '1px solid #ccc',
        borderRadius: '8px',
        overflowX: 'auto',
        '@media (min-width: 601px)': {
          overflowX: 'visible',
        },
        '@media (max-width: 600px)': {
          p: 0.5,
          maxWidth: '400px',
          fontSize: '60%',
          width: '100%',
        },
      }}
    >
      <Box sx={{ p: 1 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ flexDirection: 'row', flexWrap: 'nowrap', maxWidth: '100%' }}
        >
          <Grid item>
            <div style={boxStyle}>
              <div
                style={{
                  ...innerBoxStyle,
                  height: '50px',
                  flexDirection: 'column',
                  marginLeft: '5px',
                }}
              >
                <Typography variant="body1" sx={{ color: 'black' }}>
                  OVER
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: 'black', fontWeight: 'bold' }}
                >
                  {currentOver}
                </Typography>
              </div>
            </div>
          </Grid>
          {overData.map((ballData, index) => (
            <Grid item key={index}>
              <div
                style={{ ...circleBoxStyle, backgroundColor: ballData.color }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: 'black', fontWeight: 'bold' }}
                >
                  {ballData.runs}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default OverTracker
