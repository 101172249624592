import React, { useEffect } from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTeamData } from '../../redux/match/match.action';
import ProfilePic from './ProfilePic';
import UserProfile from './UserProfile';
import NoDataComponent from '../NoDataComponent';
import HeadingWithLines from '../Headings/HeadingWithLines';
import MainWidthWrapper from '../MainWidthWrapper';
import MainHeading from '../Headings/MainHeading';
import { Link } from 'react-router-dom';
import TeamIcon from '../../assets/Team-icon.png'; 

const TeamProfilePage = () => {
  const { teamObjectId } = useParams();
  const dispatch = useDispatch(); 
  const teamData = useSelector((state) => state.match.teamData);

  useEffect(() => {
    if (teamObjectId) {
      dispatch(fetchTeamData(teamObjectId));
    }
  }, [dispatch, teamObjectId]);

  return (
    <MainWidthWrapper>
      <Box sx={{ py: 2.5 }}>
        <MainHeading label={'TEAM PROFILE'} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0, mx: 1, mb: 0, borderBottom: '2px solid #33333326', width: '100%' }}>
      <Box>
          <ProfilePic profileType="team" imageUrl={teamData?.logo} />
        </Box>
        <Box >
        <UserProfile name={teamData?.name} username={`@${teamData?.teamHandle}`} isHeader={true} />
        </Box>
      </Box>
      <Box sx={{ px: 1 }}>
      <HeadingWithLines
    label={
        <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: '1' }}>
            <img 
                src={TeamIcon} 
                alt="Team Icon" 
                style={{ 
                    height: '2em',
                    marginBottom: '0.3em',
                    marginRight: '0.5em',
                }} 
            /> 
            {`PLAYERS IN THE TEAM - ${teamData?.players.length || 0}`}
        </Box>
    }
    lineWidth={'0.5px'}
    sx={{
        fontWeight: 'bold',
        color: '#333',
    }}
        />
        </Box>
        <Box>
        <List
    sx={{ pt: 0 }} >
    {teamData?.players && teamData.players.length > 0 ? (
    teamData.players.map((player, index) => (
      <ListItem
        key={index}
        component={Link} 
        to={`/player/${player.userObjectId.userName}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
        sx={{ display: 'flex', alignItems: 'center', columnGap: 0, mx: 0, paddingLeft: 0, borderBottom: '1px solid #ccc' }}
      >
        <ProfilePic profileType="player" />
        <div>
          <Typography variant="subtitle1">
            <span style={{ fontWeight: 'bold'}}>@{player.userObjectId.userName}</span>
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#888', marginBottom: 0 }}>{player.playername}</Typography>
        </div>
      </ListItem>
      ))
       ) : (
         <NoDataComponent label={'NO PLAYERS ADDED YET.'} />
        )}
        </List>
      </Box>
    </MainWidthWrapper>
  );
};

export default TeamProfilePage;
