import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function PlayerProfileTabs({ value, setValue }) {
  const tabs = [
    { value: 0, label: "MATCHES" },
    { value: 1, label: "STATS" },
  ];

  const changeTab = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
   justifyContent: "center",
    px: 3,
     width: "100%",
     mt: 1.5,
    maxWidth: 1200,
    margin: "5px auto",
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', 
    gap: '0px', 
    }}>
      {tabs.map((tab) => (
        <Button
          key={tab.value}
          sx={{
            borderBottom: `3px solid ${value === tab.value ? "black" : "#CBCBCB"}`,
            color: "black",
            fontWeight: value === tab.value ? 700 : 500,
            minWidth: "114px",
            borderRadius: 0,
            "&:hover": {
              borderBottom: value === tab.value && "2px solid black",
            },
          }}
          color="primary"
          onClick={() => changeTab(tab.value)}
        >
          {tab.label}
        </Button>
      ))}
    </Box>
  );
}
