import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

const cellStyle = {
  fontSize: "12px",
  color: "white",
  borderBlockColor: "black",
  px: 0,
  py: 1,
};

const TournamentTable = ({ pointsTable = [], ungroupedTeams = [] }) => {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: "12px", width: { sm: "100%", xs: "315px" }, mx: { sm: "auto"}, ml: { xs: 0 } }}>
      <Table sx={{ backgroundColor: "#2B2B3D" }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...cellStyle, borderBlockColor: "white" }} align="center">
              #
            </TableCell>
            <TableCell sx={{ ...cellStyle, borderBlockColor: "white" }} align="left">
              Teams
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              P
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              W
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              L
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              T
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              Pts
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              NRR
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pointsTable.map((tournament, index) => (
            <TableRow key={index} sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}>
              <TableCell align="center" component="th" scope="tournament" sx={{ ...cellStyle, border: "none" }}>
                {index + 1}
              </TableCell>
              <TableCell align="left" sx={{ ...cellStyle, border: "none" }}>
              <Link to={`/team/${tournament.teamObjectId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {tournament.teamName}
                </Link>
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {tournament.playedMatch}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {tournament.won}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {tournament.loss}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {tournament.tie}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {tournament.points}
              </TableCell>
              <TableCell align="center" sx={cellStyle}>
                {tournament.nRR}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
       <Table sx={{ backgroundColor: "#2B2B3D" , marginTop: 2, borderRadius: "12px" }} size="small" aria-label="a dense table">
       <TableHead>
          <TableRow>
            <TableCell sx={{ ...cellStyle, borderBlockColor: "white" }} align="center">
              #
            </TableCell>
            <TableCell sx={{ ...cellStyle, borderBlockColor: "white" }} align="left">
              Teams
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              P
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              W
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              L
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              T
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              Pts
            </TableCell>
            <TableCell align="center" sx={{ ...cellStyle, borderBlockColor: "white" }}>
              NRR
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ungroupedTeams.map((team, index) => (
            <TableRow key={index} sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}>
            <TableCell align="center" component="th" scope="team" sx={{ ...cellStyle, border: "none" }}>
              {index + 1}
            </TableCell>
            <TableCell align="left" sx={{ ...cellStyle, border: "none" }}>
            <Link to={`/team/${team._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {team.name}
                </Link>
            </TableCell>
            <TableCell align="center" sx={cellStyle}>
              {team.playedMatch}
            </TableCell>
            <TableCell align="center" sx={cellStyle}>
              {team.won}
            </TableCell>
            <TableCell align="center" sx={cellStyle}>
              {team.loss}
            </TableCell>
            <TableCell align="center" sx={cellStyle}>
              {team.tie}
            </TableCell>
            <TableCell align="center" sx={cellStyle}>
              {team.points}
            </TableCell>
            <TableCell align="center" sx={cellStyle}>
              {team.nRR}
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TournamentTable;
