import React from 'react';
import { Box,Typography } from '@mui/material';

const UserProfile = ({ name, username, isHeader }) => {
    return (
        <Box sx={{ columnGap: 0 }}>
        {isHeader ? (
          <>
           <Typography variant="subtitle1" sx={{ textTransform: 'uppercase', fontSize: '1.1rem' , marginBottom: -1, fontWeight: 'bold' }}>
            {name}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'purple' }}>
            {username}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="subtitle1">{username}</Typography>
            <Typography variant="subtitle1">
              {name}
            </Typography>
          </>
        )}
      </Box>
    );
};

export default UserProfile;
