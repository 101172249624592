import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import MatchTable from '../../../components/allMatchData/matches.datagrid'
import { fetchAllMatches } from '../../../redux/match/match.action'

const MatchesTablePage = () => {
  const dispatch = useDispatch()
  const matchData = useSelector((state) => state.match)
  const { currentPage, pageSize } = matchData
  useEffect(() => {
    dispatch(fetchAllMatches(currentPage, pageSize, null))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize])
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gully Six | All Matches</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <MatchTable />
    </div>
  )
}

export default MatchesTablePage
